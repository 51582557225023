<template>
  <div class="bg-wrap">
    <div class="container">
      <div v-if="filters.id > 0" class="row"><div class="col-12"><p class="small"><router-link to="/cases" v-on:click.native="filters.id = 0">« View all cases</router-link></p></div></div>
      <div v-if="filters.id == 0" id="filter-items" class="filter-items w-sort">
        <div><h4>Filter by</h4></div>
        <div>
          <select v-model="filters.expertise" class="custom-select" style="width: 115px;">
            <option value="myexp">My interest</option>
            <option value="">Show all</option>
            <option v-for="option in indications" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
        <div>
          <select v-model="filters.anatomy" class="custom-select">
            <option value="">Anatomy</option>
            <option>Skull</option>
            <option>Humerus</option>
            <option>Radius</option>
            <option>Ulna</option>
            <option>Hand</option>
            <option>Sternum</option>
            <option>Spine</option>
            <option>Pelvis</option>
            <option>Femur</option>
            <option>Tibia</option>
            <option>Fibula</option>
            <option>Foot</option>
            <option>Mastoid</option>
            <option>Other</option>
          </select>
        </div>
        <div>
          <select v-model="filters.age_group" class="custom-select">
            <option value="">Age group</option>
            <option>pediatric</option>
            <option>adult</option>
            <option>geriatric</option>
          </select>
        </div>
        <div>
          <select v-model="filters.product" class="custom-select" style="width: 155px;">
            <option value="">Bonalive Products</option>
            <option value="8">Bonalive® granules small</option><option value="9">Bonalive® granules medium</option><option value="10">Bonalive® granules large</option><option value="11">Bonalive® putty</option><option value="12">Bonalive® putty MIS</option><option value="13">Bonalive® granules CMF</option>
          </select>
        </div>
        <div>
          <select v-model="filters.lang" class="custom-select">
            <option value="">Languages</option>
            <option v-for="option in languages" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
        <div class="search">
          <input type="text" class="form-control" v-model="search" name="search" placeholder="Search cases" value="">
          <button class="btn search" type="submit"><i class="far fa-search"></i></button>
        </div>
        <div class="filters-sort">
          <i class="fas fa-sort-amount-down"></i>
          <select name="sort"  v-model="sort" class="custom-select">
            <option value="id:desc">Latest first</option>
            <option value="title:asc">Alphabetically</option>
          </select>
        </div>
        <div class="cta-btn">
          <router-link class="btn btn-primary btn-sm" :to="{ path: '/support', query: { form: 'report-case' } }"><i class="fas fa-sign-out fa-rotate-270 mr-2"></i> Share your case</router-link>
        </div>
      </div>
      <div class="loading results" v-if="loading"></div>
      <div v-if="!loading" class="items">
        <div class="row item case" v-for="(item, index) in items" :key="item.id" :id="['BaCase-' + item.id]" :class="[item.open ? 'open' : '']">
          <span class="more-toggle" v-on:click.stop.prevent="show(index)"><i class="far fa-plus"></i><i class="far fa-minus"></i></span>
          <div class="col-12 case-info" v-if="!item.open">
            <div class="row">
              <div class="col-12 col-lg-2">
                <img class="case-thumb" :src="item.hcp_thumb" v-if="item.hcp_thumb" />
              </div>
              <div class="col-12 col-lg-10 py-4">
                <p><strong>{{ item.title }}</strong></p>
              </div>
            </div>
          </div>

          <div class="col-12 more-info">
            <div class="row">
              <div class="col-12">
                <div class="row info">
                  <div class="col-12 col-lg-2 pb-0 pb-lg-3"><img class="case-thumb" :src="item.hcp_thumb" v-if="item.hcp_thumb" /></div>
                  <div class="col-12 col-lg-10 py-4"><p class="title"><strong>{{ item.title }}</strong></p></div>
                  <div class="col-12 col-sm-6 col-lg-3"><h5>Hospital:</h5><p>{{ item.hospital }}</p></div>
                  <div class="col-12 col-sm-6 col-lg-3"><h5>Country:</h5><p>{{ item.country_nice }}</p></div>
                  <div class="col-12 col-sm-6 col-lg-3"><h5>Indication:</h5><p>{{ item.title_indication }}</p></div>
                  <div class="col-12 col-sm-6 col-lg-3"><h5>Bonalive products:</h5><p v-html="item.products"></p></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12"><a class="btn btn-primary" target="_blank" :href="'https://www.bonalivepro.com/assets/components/bonalive/uploads/' + item.presentation">Open case</a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-primary" role="alert" v-if="!loading && items.length == 0">No items found!</div>
      <ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 0">
        <li v-for="n in pages" class="page-item" :class="[page == (n-1) ? 'active' : '']" :key="n"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loading: true,
      indications: this.shc_indications,
      languages: this.shc_languages,
      search: '',
      filters: {
        id: 0,
        anatomy: '',
        age_group: '',
        expertise: 'myexp',
        lang: 'EN',
        product: ''
      },
      items: [],
      visible: 0,
      perpage: 10,
      pages: 0,
      page: 0,
      sort: 'id:desc',
    }
  },
  created: function () {
    var load = false;
    if(typeof this.$route.query.indication != 'undefined') {
      this.filters.expertise = this.$route.query.indication;
      load = true;
    }

    if(typeof this.$route.query.id != 'undefined') {
      this.filters.id = this.$route.query.id;
      load = true;
    }

    this.debouncedLoad = this._.debounce(this.load, 500)
    if(!load) this.load();
  },
  methods: {
    load: function() {
      var vm = this;
      vm.loading = true;
      var sort = vm.sort.split(':');
      this.axios.get('/api/cases?limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage) + '&sort=' + sort[0] + '&dir=' + sort[1] + '&search=' + vm.search + '&filters=' + JSON.stringify(vm.filters), { withCredentials: true })
      .then(function (response) {
        var items = response.data.results;

        Object.keys(items).forEach(function(key){ items[key].open = false; });

        vm.items = items;
        vm.pages = Math.ceil(response.data.total / vm.perpage);
        vm.loading = false;
      })
      .catch(function () {
        vm.items = [];
        vm.pages = 0;
        vm.loading = false;
      })
    },
    show: function (index) {
      if(this.items[index].open == true) this.items[index].open = false;
      else this.items[index].open = true;
    },
    showpage: function (id) {
      this.page = id;
    }
  },
  watch: {
    page: function () {
      this.load();
    },
    search: function () {
      this.debouncedLoad();
    },
    sort: function () {
      this.load();
    },
    filters: {
      handler: function () {
        if(this.page == 0) {
          this.load();
        } else {
          this.page = 0;
        }
      },
      deep: true
    }
  }
}
</script>
