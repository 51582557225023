<template>
  <div class="bg-wrap">
    <div class="container">
      <div v-if="filters.id > 0" class="row"><div class="col-12"><p class="small"><router-link to="/publications" v-on:click.native="filters.id = 0">« View all publications</router-link></p></div></div>
      <div v-if="filters.id == 0" id="filter-items" class="filter-items w-sort">
        <div><h4>Filter by</h4></div>
        <div>
          <select v-model="filters.indication" class="form-control custom-select" style="width: 105px;">
            <option value="">Indication</option>
            <option value="1">Inhibition of bacterial growth</option><option value="2">Bone regeneration</option><option value="3">Bone infection</option><option value="4">Trauma surgery</option><option value="5">Spine surgery</option><option value="6">Benign bone tumor surgery</option><option value="7">Mastoid surgery</option><option value="8">Oral and maxillofacial surgery</option><option value="9">Frontal sinus surgery</option><option value="10">Mechanical studies</option><option value="11">Safety</option><option value="12">Other</option><option value="13">Diabetic foot osteomyelitis surgery</option>
          </select>
        </div>
        <div>
          <select v-model="filters.publication_type" class="form-control custom-select" style="width: 140px;">
            <option value="">Publication type</option>
            <option>article</option>
            <option>book</option>
            <option>thesis</option>
            <option>presentation</option>
            <option>poster</option>
          </select>
        </div>
        <div>
          <select v-model="filters.study_type" class="form-control custom-select" style="width: 100px;">
            <option value="">Study type</option>
            <option>clinical</option>
            <option>preclinical</option>
            <option>in vitro</option>
          </select>
        </div>
        <div>
          <select v-model="filters.study_design" class="form-control custom-select" style="width: 115px;">
            <option value="">Study design</option>
            <option>review</option>
            <option>RCT</option>
            <option>prospective</option>
            <option>retrospective</option>
            <option>case report</option>
          </select>
        </div>
        <div>
          <select v-model="filters.ct_classification" class="form-control custom-select" style="width: 240px;">
            <option value="">Classification of clinical trials</option>
            <option>L1a</option>
            <option>L1b</option>
            <option>L2</option>
            <option>L3</option>
            <option>L4</option>
          </select>
        </div>
        <div>
          <div class="custom-control custom-checkbox pt-2">
            <input type="checkbox" class="custom-control-input" v-model="filters.important" id="important">
            <label class="custom-control-label" for="important">Most important</label>
          </div>
        </div>
        <div>
          <div class="custom-control custom-checkbox pt-2">
            <input type="checkbox" class="custom-control-input" v-model="filters.expertise" id="myexp">
            <label class="custom-control-label" for="myexp">Only my area of interest</label>
          </div>
        </div>
        <div class="search">
          <input type="text" class="form-control" v-model="search" name="search" placeholder="Search publications" value="">
          <button class="btn search" type="submit"><i class="far fa-search"></i></button>
        </div>
        <div class="filters-sort">
          <i class="fas fa-sort-amount-down"></i>
          <select name="sort"  v-model="sort" class="custom-select">
            <option value="pub_year:desc">Latest first</option>
            <option value="title:asc">Alphabetically</option>
            <option value="ct_classification:asc">Classification of clinical trials</option>
          </select>
        </div>
      </div>
      <div class="loading results" v-if="loading"></div>
      <div v-if="!loading" class="items">

        <div class="row item article" v-for="(item, index) in items" :key="item.id" :id="['BaArticle-' + item.id]">
          <span class="more-toggle d-none" v-on:click.stop.prevent="show(index)"><i class="far fa-plus"></i><i class="far fa-minus"></i></span>
          <div class="col-lg-1 image d-none d-lg-block"><img :src="item.thumbnail"></div>
          <div class="col-12 col-lg-4 title "><p><strong>{{ item.title }}</strong></p></div>
          <div class="col-12 col-lg-7">
            <div class="row">
              <div class="col-12 col-lg-3 col-xl-4"><p>{{ item.author }}<br>{{ item.pub_year }}</p></div>
              <div class="col-6 col-lg-3 smaller"><p>{{ item.title_indication }}</p></div>
              <div class="col-6 col-lg-3 smaller last text-uppercase"><p>{{ item.study_type }}<br>{{ item.study_design }}<br>{{ item.ct_classification }}</p></div>
              <div class="col-12 col-lg-3 col-xl-2 pl-lg-0"><a class="btn btn-primary p-lg-2 rounded" target="_blank" :href="item.link">Go to abstract</a></div>
            </div>
          </div>
        </div>

      </div>
      <div class="alert alert-primary" role="alert" v-if="!loading && items.length == 0">No publications found!</div>
      <ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 0">
        <li v-for="n in pages" class="page-item" :class="[page == (n-1) ? 'active' : '']" :key="n"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loading: true,
      search: '',
      filters: {
        id: 0,
        indication: '',
        publication_type: '',
        study_type: '',
        study_design: '',
        ct_classification: '',
        important: false,
        expertise: false
      },
      items: [],
      visible: 0,
      perpage: 10,
      pages: 0,
      page: 0,
      sort: 'ct_classification:asc',
    }
  },
  created: function () {
    var load = false;
    if(typeof this.$route.query.indication != 'undefined') {
      this.filters.indication = this.$route.query.indication;
      this.sort = 'ct_classification:asc';
      load = true;
    }

    if(typeof this.$route.query.id != 'undefined') {
      this.filters.id = this.$route.query.id;
      load = true;
    }

    this.debouncedLoad = this._.debounce(this.load, 500)
    if(!load) this.load();
  },
  methods: {
    load: function() {
      var vm = this;
      vm.loading = true;
      var sort = vm.sort.split(':');
      this.axios.get('/api/articles?limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage) + '&sortby=' + sort[0] + '&sortdir=' + sort[1] + '&search=' + vm.search + '&filters=' + JSON.stringify(vm.filters), { withCredentials: true })
      .then(function (response) {
        var items = response.data.results;

        Object.keys(items).forEach(function(key){ items[key].open = false; });

        vm.items = items;
        vm.pages = Math.ceil(response.data.total / vm.perpage);
        vm.loading = false;
      })
      .catch(function () {
        vm.items = [];
        vm.pages = 0;
        vm.loading = false;
      })
    },
    show: function (index) {
      if(this.items[index].open == true) this.items[index].open = false;
      else this.items[index].open = true;
    },
    showpage: function (id) {
      this.page = id;
    }
  },
  watch: {
    page: function () {
      this.load();
    },
    search: function () {
      this.debouncedLoad();
    },
    sort: function () {
      this.load();
    },
    filters: {
      handler: function () {
        if(this.page == 0) {
          this.load();
        } else {
          this.page = 0;
        }
      },
      deep: true
    }
  }
}
</script>
