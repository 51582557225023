<template>
  <div class="bg-wrap">
    <div class="container">
      <div v-if="filters.id > 0" class="row"><div class="col-12"><p class="small"><router-link to="/brochures" v-on:click.native="filters.id = 0">« View all brochures</router-link></p></div></div>
      <div v-if="filters.id == 0" id="filter-items" class="filter-items">
        <div><h4>Filter by</h4></div>
        <div>
          <select v-model="filters.expertise" class="custom-select" style="width: 150px;">
            <option value="myexp">My interest</option>
            <option value="">Show all</option>
            <option v-for="option in indications" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
        <div>
          <select v-model="filters.lang" class="custom-select">
            <option value="">Languages</option>
            <option v-for="option in languages" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
      </div>
      <div class="loading results" v-if="loading"></div>
      <div v-if="!loading" class="row items">
        <div class="col-12 col-md-6 col-lg-4" v-for="(item, index) in items" :key="item.id">
          <div class="material brochure" :id="[ 'BaMaterial-' + item.id ]">
            <h3>{{ item.title }}</h3>
            <div class="image">
              <img :src="item.thumbnail">
            </div>
            <p>{{ item.description }}</p>
            <a class="btn btn-primary" :href="item.link" target="_blank" v-on:click.stop="showFlip(index, $event)">Open brochure</a>
          </div>
        </div>
      </div>
      <div class="alert alert-primary" role="alert" v-if="!loading && items.length == 0">No items found!</div>
      <ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 0">
        <li v-for="n in pages" class="page-item" :class="[page == (n-1) ? 'active' : '']" :key="n"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
      </ul>
    </div>

    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-xl" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ modal_title }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModal = false">
                    <span aria-hidden="true" >&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="embed-responsive embed-responsive-4by3">
                        <iframe class="embed-responsive-item" :src="modal_embed" allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loading: true,
      indications: this.shc_indications,
      languages: this.shc_languages,
      search: '',
      filters: {
        id: 0,
        category_id: 1,
        lang: 'EN',
        expertise: 'myexp'
      },
      items: [],
      visible: 0,
      perpage: 18,
      pages: 0,
      page: 0,
      showModal: false,
      modal_title: '',
      modal_embed: ''
    }
  },
  created: function () {
    var load = false;

    if(typeof this.$route.query.id != 'undefined') {
      this.filters.id = this.$route.query.id;
      load = true;
    }

    this.debouncedLoad = this._.debounce(this.load, 500)
    if(!load) this.load();
  },
  methods: {
    load: function() {
      var vm = this;
      vm.loading = true;
      this.axios.get('/api/materials?limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage) + '&search=' + vm.search + '&filters=' + JSON.stringify(vm.filters), { withCredentials: true })
      .then(function (response) {
        var items = response.data.results;

        Object.keys(items).forEach(function(key){ items[key].dlopen = false; });

        vm.items = items;
        vm.pages = Math.ceil(response.data.total / vm.perpage);
        vm.loading = false;
      })
      .catch(function () {
        vm.items = [];
        vm.pages = 0;
        vm.loading = false;
      })
    },
    showdl: function (index) {
      if(this.items[index].dlopen == true) this.items[index].dlopen = false;
      else this.items[index].dlopen = true;
    },
    showpage: function (id) {
      this.page = id;
    },
    showFlip: function (index, event) {
      var vm = this;
      if(vm.items[index].link.includes("fliphtml5.com")) {
        vm.modal_embed = '//'+vm.items[index].link.split("://")[1];
        vm.modal_title = vm.items[index].title;
        vm.showModal = true;
        event.preventDefault();
      }
    }
  },
  watch: {
    showModal(val) {
      const el = document.body;
      if (val) {
        el.classList.add('modal-open');
      } else {
        el.classList.remove('modal-open');
      }
    },
    page: function () {
      this.load();
    },
    search: function () {
      this.debouncedLoad();
    },
    filters: {
      handler: function () {
        if(this.page == 0) {
          this.load();
        } else {
          this.page = 0;
        }
      },
      deep: true
    }
  }
}
</script>
