<template>
  <div class="bg-wrap faq">
    <div class="container">
      <div class="loading results" v-if="loading"></div>
      <div class="row my-4" v-if="!loading">

        <div class="col-12 col-lg-4">
          <div class="content-box contact-container faq-menu">
            <h2>Categories</h2>
            <ul class="faq-cat-nav">
              <li v-for="(cat) in categories" :key="cat.id" ><a href="#" :class="[ visible == cat.id ? 'active' : '' ]" v-on:click.stop.prevent="showcat(cat.id)">{{ cat.title }}</a></li>
            </ul>
            <p>Didn't find an answer to your question?<br>Please <router-link :to="{ path: '/support', query: { form: 'question' }}">send it our way</router-link>!</p>
          </div>
        </div>
        <div class="col-12 col-lg-8 mb-4 mb-lg-0">
          <div class="content-box contact-container faq-acc">
            <h2>Frequently asked questions</h2>
            <div class="accordion">

              <div class="card" v-for="(item, index) in items" :key="item.id" :class="{ 'd-none': visible != item.category_id }">
                <div class="card-header" >
                  <h2 class="mb-0"><button v-on:click.stop.prevent="show(index)" class="btn btn-link" :class="[ item.open ? '' : 'collapsed' ]" type="button">{{ item.title }}</button></h2>
                </div>
                <div class="collapse" :class="[ item.open ? 'show' : '' ]">
                  <div class="card-body" v-html="item.content"></div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-primary" role="alert" v-if="!loading && items.length == 0">No FAQs found!</div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loading: true,
      items: [],
      categories: [],
      visible: 0,
    }
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function() {
      var vm = this;
      vm.loading = true;
      this.axios.get('/api/faqs', { withCredentials: true })
      .then(function (response) {
        var items = response.data.results;

        Object.keys(items).forEach(function(key) {
          items[key].open = false;
          var add = true;
          Object.keys(vm.categories).forEach(function(ckey){
            if(vm.categories[ckey].id == items[key].category_id) add = false;
          });
          if(add) vm.categories.push({ 'id': items[key].category_id, 'title': items[key].category_name, 'sort': items[key].category_sort });
        });

        vm.categories.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

        if(typeof vm.categories[0] != undefined) vm.visible =  vm.categories[0].id;

        vm.items = items;
        vm.pages = Math.ceil(response.data.total / vm.perpage);
        vm.loading = false;
      })
      .catch(function () {
        vm.$router.push('/');
      })
    },
    show: function (index) {
      if(this.items[index].open == true) this.items[index].open = false;
      else this.items[index].open = true;
    },
    showcat: function (id) {
      this.visible = id;
    }
  }
}

</script>
