<template>
  <div class="indication">
    <div class="indication-wrap">
      <div class="loading results" v-if="loading"></div>
      <div class="container" v-if="!loading">
        <div class="row">
          <div class="col-12 col-lg-6">
            <img :src="[ '/assets/img/landing_' + image + '.png' ]" />
          </div>
          <div class="col-12 col-lg-6">
            <div class="indication-info">
              <h1>{{ title }}</h1>
              <div v-html="content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="!loading">

      <div class="row">
        <div class="col-12 px-0">
          <h1 class="text-center my-5">Overview</h1>
        </div>
      </div>

      <div class="row result-related" v-if="videos.length > 0">
        <div class="col-12 col-lg-4 pl-md-0">
          <h3>Video</h3>
          <p class="mt-3"><router-link to="/videos" class="more-link">See all »</router-link></p>
        </div>
        <div class="col-12 col-lg-8">
          <div class="related">
            <div class="row" v-for="(item, index) in videos" :key="index">
              <div class="col-12 col-md-5 mb-3 mb-md-0">
                <a target="_blank" :href="item.link" v-on:click.stop="showVideo(item.link, item.title, $event)"><div class="thumb-crop" :style="{ backgroundImage: 'url(' + item.thumb + ')' }"><img :src="item.thumb" /></div></a>
              </div>
              <div class="col-12 col-md-7 my-auto pl-lg-45">
                <a target="_blank" :href="item.link" v-on:click.stop="showVideo(item.link, item.title, $event)">{{ item.title }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row result-related" v-if="cases.length > 0">
        <div class="col-12 col-lg-4 pl-md-0">
          <h3>Cases</h3>
          <p class="mt-3"><router-link :to="{ path: '/cases', query: { indication: exp_id }}" class="more-link" >See all »</router-link></p>
        </div>
        <div class="col-12 col-lg-8">
          <div class="related">
            <div class="row" v-for="(item, index) in cases" :key="index">
              <div class="col-12 col-md-5 mb-3 mb-md-0">
                <a target="_blank" :href="item.link"><img :src="item.thumb" /></a>
              </div>
              <div class="col-12 col-md-7 my-auto pl-lg-45">
                <a target="_blank" :href="item.link">{{ item.title }} <em>{{ item.hospital }}, {{ item.country }}</em></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row result-related" v-if="articles.length > 0">
        <div class="col-12 col-lg-4 pl-md-0">
          <h3>Publications</h3>
          <p class="mt-3"><router-link :to="{ path: '/publications', query: { indication: ind_id }}" class="more-link">See all »</router-link></p>
        </div>
        <div class="col-12 col-lg-8">
          <div class="related">
            <div class="row" v-for="(item, index) in articles" :key="index">
              <div class="col-12 col-md-5 mb-3 mb-md-0">
                <a target="_blank" :href="item.link"><img :src="[ '/assets/img/pl-article.png' ]" /></a>
              </div>
              <div class="col-12 col-md-7 my-auto pl-lg-45">
                <a target="_blank" :href="item.link">{{ item.title }} <em>{{ item.author }} {{ item.pub_year }}</em></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row result-related" v-if="brochures.length > 0">
        <div class="col-12 col-lg-4 pl-md-0">
          <h3>Brochures</h3>
        </div>
        <div class="col-12 col-lg-8">
          <div class="related">
            <div class="row" v-for="(item, index) in brochures" :key="index">
              <div class="col-12 col-md-5 mb-3 mb-md-0">
                <a target="_blank" :href="item.link" v-on:click.stop="showFlip(item.link, item.title, $event)"><div class="thumb-crop full-height" :style="{ backgroundImage: 'url(' + item.thumb + ')' }"><img :src="item.thumb" /></div></a>
              </div>
              <div class="col-12 col-md-7 my-auto pl-lg-45">
                <a target="_blank" :href="item.link" v-on:click.stop="showFlip(item.link, item.title, $event)">{{ item.title }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="0">
        <div class="col-12 px-lg-0 pb-4">
          <router-link v-if="title == 'Mastoid surgery'" to="/consult" class="btn btn-primary btn-full arrow-right btn-blue">Consult a colleague</router-link>
        </div>
      </div>

    </div>

    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-xl" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ modal_title }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModal = false">
                    <span aria-hidden="true" >&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="embed-responsive embed-responsive-16by9" v-if="embed_type == 'video'">
                        <iframe class="embed-responsive-item" :src="[ 'https://player.vimeo.com/video/' + embed ]" allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                      <div class="embed-responsive embed-responsive-4by3" v-if="embed_type == 'pdf'">
                        <iframe class="embed-responsive-item" :src="embed" allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

  </div>

</template>
<script>
export default {
  data: function () {
    return {
      loading: true,
      visible: false,
      title: '',
      content: '',
      image: '',
      ind_id: '',
      exp_id: '',
      cases: [],
      articles: [],
      videos: [],
      brochures: [],
      showModal: false,
      modal_title: '',
      embed_type: '',
      embed: ''
    }
  },
  created: function () {
    var vm = this;
    if(typeof vm.$route.params.id != 'undefined') {
      vm.visible = vm.$route.params.id;
      vm.checkimage();
    }
  },
  methods: {
    load: function() {
      var vm = this;
      vm.loading = true;

      var area = '';
      if(typeof vm.$route.query.area != 'undefined') area = vm.$route.query.area;

      this.axios.get('/api/indication/' + vm.visible + '?area=' + area, { withCredentials: true })
      .then(function (response) {
        if(response.data.success) {

          vm.loading = false;

          vm.title = response.data.object.title;
          vm.content = response.data.object.content;
          vm.cases = response.data.object.cases;
          vm.articles = response.data.object.articles;
          vm.videos = response.data.object.videos;
          vm.brochures = response.data.object.brochures;

          vm.ind_id = response.data.object.indication_id;
          vm.exp_id = response.data.object.expertise_id;

        } else {
          vm.$router.push('/indication');
        }
      }).catch(function () {
        vm.$router.push('/indication');
      })

    },
    checkimage: function() {
      var vm = this;
      if(vm.$route.params.id == 'mastoid' || vm.$route.params.id == 'spine') {
        vm.image = vm.$route.params.id;
      } else {
        if(typeof vm.$route.query.area != 'undefined') {
          vm.image = vm.$route.query.area;
        } else {
          vm.image = 'arms';
        }
      }
    },
    showVideo: function (link, title, event) {
      var vm = this;
      if(link.includes("vimeo.com") && !link.includes("folder")) {
        var id = link.split("vimeo.com/")[1];
        id = id.split("/")[0];
        vm.embed = id;
        vm.embed_type = 'video';
        vm.modal_title = title;
        vm.showModal = true;

        event.preventDefault();
      }
    },
    showFlip: function (link, title, event) {
      var vm = this;
      if(link.includes("fliphtml5.com")) {
        vm.embed = '//'+link.split("://")[1];
        vm.embed_type = 'pdf';
        vm.modal_title = title;
        vm.showModal = true;
        event.preventDefault();
      }
    }
  },
  watch: {
    showModal(val) {
      const el = document.body;
      if (val) {
        el.classList.add('modal-open');
      } else {
        el.classList.remove('modal-open');
      }
    },
    visible: function () {
      this.load();
    },
    $route(to) {
      var vm = this;
      if(typeof to.params.id != 'undefined') {
        vm.visible = to.params.id;
        vm.checkimage();
      }
    }
  }
}

</script>
