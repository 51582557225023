<template>
  <div class="form-wrap">
    <h2 class="mt-3">Share a Bonalive® bioactive glass patient case</h2>
    <p>Do you have a patient case that you would like to share with your colleagues? Upload it here to show how you solved a case utilizing Bonalive® bioactive glass. The case will be reviewed and added to the database here in Smart Healing Center.</p>

    <form enctype="multipart/form-data" ref="form" id="contact" @submit.prevent="sendform">
      <div class="form-group">
        <div class="prod-wrap">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="type-1" v-model="form.type" value="new case" required>
            <label class="custom-control-label" for="type-1">This is the first time reporting this patient case in the Smart Healing Center</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="type-2" v-model="form.type" value="follow-up" >
            <label class="custom-control-label" for="type-2">This is follow-up on an earlier case already reported in the Smart Healing Center</label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Case title</label>
        <input class="form-control" placeholder="" v-model="form.title" required />
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Courtesy of</label>
        <textarea class="form-control" v-model="form.courtesyof" placeholder="Surgeon, hospital, city and country. Please state whether we can disclose your name." ></textarea>
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Patient history</label>
        <textarea class="form-control" v-model="form.patienthistory" placeholder="Age, gender, diagnosis."></textarea>
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Bacterial culture</label>
        <input class="form-control" placeholder="Name the bacteria in case of an infection" v-model="form.bacterialculture" />
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Defect size</label>
        <input class="form-control" placeholder="In cubic centimeters (cc)" v-model="form.defectsize" />
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Bonalive product</label>
        <div class="prod-wrap">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" v-model="form.products" id="prod-8" value="Bonalive® granules">
            <label class="custom-control-label" for="prod-8">Bonalive® granules</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" v-model="form.products" id="prod-13" value="Bonalive® granules CMF">
            <label class="custom-control-label" for="prod-13">Bonalive® granules CMF</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" v-model="form.products" id="prod-11" value="Bonalive® putty">
            <label class="custom-control-label" for="prod-11">Bonalive® putty</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" v-model="form.products" id="prod-12" value="Bonalive® putty MIS">
            <label class="custom-control-label" for="prod-12">Bonalive® putty MIS</label>
          </div>
        </div>
        <label class="font-weight-bold mt-3">Amount/ratio of Bonalive products</label>
        <input class="form-control" placeholder="In cubic centimeters (cc)" v-model="form.productandamount" />
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Other fillers</label>
        <input class="form-control" placeholder="e.g. autograft/allograft that has been used" v-model="form.otherfillers" />
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Operation</label>
        <textarea class="form-control" v-model="form.operation" placeholder="Describe the operational technique and other surgery-related information."></textarea>
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Clinical outcome</label>
        <textarea class="form-control" v-model="form.clinicaloutcome" placeholder="e.g. quality of life, recurrence of infection, recurrence of tumors, follow-up."></textarea>
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Pictures</label>
        <p class="mb-3">Upload the case photos here, e.g. pre-op, peri-op, xx month post-op.<br/>Please upload high resolution photos.</p>
        <div class="row">
          <div class="col-12 col-md-6">
            <input type="file" @change="selectFile" name="pictures" ref="file" multiple="multiple" />
          </div>
          <div class="col-12 col-md-6">
            <p v-if="files.length > 0" class="font-weight-normal">{{ files.length }} files selected <span class="ml-2 font-weight-bold cursor-pointer" v-on:click="clearFiles()"><i class="fas fa-trash-alt"></i></span></p>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="font-weight-bold">Notes</label>
        <textarea class="form-control" v-model="form.notes" placeholder=""></textarea>
      </div>

      <div class="form-group">
        <div class="prod-wrap">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="consent" v-model="form.consent" value="yes" required>
            <label class="custom-control-label" for="consent">The patient has given consent to use the case *</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="edumark" v-model="form.educationandmarketing" value="yes" required>
            <label class="custom-control-label" for="edumark">I agree that Bonalive can use this case for medical education and marketing *</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <p>Please make sure that the information and pictures provided do not include personally identifiable patient information.</p>
        <div class="alert alert-info" v-if="message"><p>{{ message }}</p></div>
        <ul>
          <li v-for="(val, key) in errors" :key="key">"{{ key }}": {{ val }}</li>
        </ul>

        <button class="btn btn-primary" type="submit">Send <i v-if="send" class="fas fa-spinner-third fa-spin m-1"></i></button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      send: false,
      message: null,
      errors: [],
      files: [],
      form: {
        type: '',
        title: '',
        courtesyof: '',
        patienthistory: '',
        bacterialculture: '',
        defectsize: '',
        products: [],
        productandamount: '',
        otherfillers: '',
        operation: '',
        clinicaloutcome: '',
        notes: '',
        consent: 0,
        educationandmarketing: 0
      }
    }
  },
  methods: {
    selectFile: function() {
      for( var i = 0; i < this.$refs.file.files.length; i++ ) {
        let file = this.$refs.file.files[i];
        if(this.files.length < 8) this.files.push(file);
      }
    },
    clearFiles: function() {
      this.files = [];
    },
    sendform: function () {
      var vm = this;
      if(!vm.send) {
        vm.send = true;
        vm.message = null;
        vm.errors = [];

        /*vm.files = [];
        for( var i = 0; i < vm.$refs.file.files.length; i++ ){
        let file = vm.$refs.file.files[i];
        vm.files.push(file);
      }*/

      let formData = new FormData();

      formData.append('type', vm.form.type);
      formData.append('title', vm.form.title);
      formData.append('courtesyof', vm.form.courtesyof);
      formData.append('patienthistory', vm.form.patienthistory);
      formData.append('bacterialculture', vm.form.bacterialculture);
      formData.append('defectsize', vm.form.defectsize);
      formData.append('product', vm.form.products);
      formData.append('productandamount', vm.form.productandamount);
      formData.append('otherfillers', vm.form.bacterialculture);
      formData.append('operation', vm.form.operation);
      formData.append('clinicaloutcome', vm.form.clinicaloutcome);
      formData.append('notes', vm.form.notes);
      formData.append('consent', vm.form.consent);
      formData.append('educationandmarketing', vm.form.educationandmarketing);

      vm.files.forEach((f,x) => {
        formData.append('file'+(x+1), f);
      });

      this.axios.post('/api/forms/reportcase', formData, { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true, })
      .then(function (response) {
        if(response.data.object.form.success) {
          vm.message = 'Message sent successfully!';

          vm.form.type = '';
          vm.form.title = '';
          vm.form.courtesyof = '';
          vm.form.patienthistory = '';
          vm.form.bacterialculture = '';
          vm.form.defectsize = '';
          vm.form.productandamount = '';
          vm.form.otherfillers = '';
          vm.form.operation = '';
          vm.form.clinicaloutcome = '';
          vm.form.notes = '';
          vm.form.consent = 0;
          vm.form.educationandmarketing = 0;
          vm.files = [];

        } else {
          vm.errors = response.data.object.form.errors;
        }
        vm.send = false;
      })
      .catch(function () {
        vm.send = false;
      })
    }
  }
}
}
</script>
