<template>
  <div class="bg-wrap">
    <div class="container" v-if="view === false">
      <div id="filter-items" class="filter-items">
        <div><h4>Filter by</h4></div>
      </div>
      <div class="loading results" v-if="loading"></div>
      <div v-if="!loading" class="row items">
        <div class="col-12 col-md-6 col-lg-4" v-for="(item, index) in items" :key="item.id">
          <div class="material video" :id="[ 'BaProductInfo-' + item.id ]">
            <h3>{{ item.title }}</h3>
            <div class="image">
              <img :src="item.thumbnail">
            </div>
            <a class="btn btn-primary" href="#" v-on:click.stop.prevent="view = index">More information</a>
          </div>
        </div>
      </div>
      <div class="alert alert-primary" role="alert" v-if="!loading && items.length == 0">No items found!</div>
      <ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 0">
        <li v-for="n in pages" class="page-item" :class="[page == (n-1) ? 'active' : '']" :key="n"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
      </ul>
    </div>
    <div class="container" v-if="view !== false">
      <div class="col-12">
        <p class="mt-4"><a href="#" v-on:click.stop.prevent="view = false">Back to all products</a></p>
        <div class="content-box product-info">
          <div class="row">
            <div class="col-12 col-lg-6">
              <h1>{{ items[view].title }}</h1>
              <div class="description" v-html="items[view].description"></div>
            </div>
            <div class="col-12 col-lg-6 pt-4">
              <div class="product-image">
                <img :src="bigimage" />
              </div>
              <div class="row row-cols-5 product-thumbs pt-4">
                <div class="col" v-for="(image, index) in items[view].images" :key="index">
                  <img :src="image.name" class="cursor-pointer" v-on:click.stop="bigimage = image.name" />
                </div>
              </div>
            </div>
            <div class="col-12 pt-5">
              <div class="content" v-html="items[view].content"></div>
              <div class="icon-links pt-4">
                <a target="_blank" v-if="items[view].brochure != ''" :href="items[view].brochure" class="pdf" v-on:click.stop="showFlip(items[view].brochure, 'Brochure', $event)">Brochure</a>
                <a target="_blank" v-if="items[view].ifu != ''" :href="items[view].ifu" class="pdf" v-on:click.stop="showFlip(items[view].ifu, 'Instructions for use', $event)">Instructions for use</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-xl" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ modal_title }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModal = false">
                    <span aria-hidden="true" >&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="embed-responsive embed-responsive-4by3">
                        <iframe class="embed-responsive-item" :src="embed" allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loading: true,
      indications: this.shc_indications,
      languages: this.shc_languages,
      items: [],
      visible: 0,
      perpage: 18,
      pages: 0,
      page: 0,
      view: false,
      bigimage: '',
      distributor: false,
      showModal: false,
      modal_title: '',
      embed: ''
    }
  },
  created: function () {
    this.debouncedLoad = this._.debounce(this.load, 500)
    this.load();
  },
  methods: {
    load: function() {
      var vm = this;
      vm.loading = true;
      this.axios.get('/api/products?limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage), { withCredentials: true })
      .then(function (response) {
        var items = response.data.results;

        vm.items = items;
        vm.pages = Math.ceil(response.data.total / vm.perpage);
        vm.loading = false;
      })
      .catch(function () {
        vm.$router.push('/');
      })
    },
    showdl: function (index) {
      if(this.items[index].dlopen == true) this.items[index].dlopen = false;
      else this.items[index].dlopen = true;
    },
    showpage: function (id) {
      this.page = id;
    },
    showFlip: function (link, title, event) {
      var vm = this;
      if(link.includes("fliphtml5.com")) {
        vm.embed = '//'+link.split("://")[1];
        vm.embed_type = 'pdf';
        vm.modal_title = title;
        vm.showModal = true;
        event.preventDefault();
      }
    }
  },
  watch: {
    showModal(val) {
      const el = document.body;
      if (val) {
        el.classList.add('modal-open');
      } else {
        el.classList.remove('modal-open');
      }
    },
    page: function () {
      this.load();
    },
    view: function (val) {
      var vm = this;
      vm.bigimage = '';
      if(val !== false && typeof vm.items[vm.view].images == 'object') {
        Object.keys(vm.items[vm.view].images).forEach(function(key){
          if(vm.bigimage == '') vm.bigimage = vm.items[vm.view].images[key].name;
        });
        //vm.bigimage = vm.items[vm.view].images[0].name
      }
    }
  }
}

</script>
