<template>
  <div id="app" v-cloak :class="{ mm_open: show_mm }">
    <div id="header-wrap" v-if="$parent.loggedin">
      <div class="container" >
        <div class="row">
          <div class="col-12">
            <router-link to="/indication"><span id="logo">Smart Healing Center</span></router-link>
            <ul class="primary-nav">
              <li><router-link to="/indication">Home</router-link></li>
              <li class="w-subnav"><span>Material database</span>
                <ul>
                  <li><router-link to="/videos">Videos</router-link></li>
                  <li><router-link to="/publications">Publications</router-link></li>
                  <li><router-link to="/cases">Cases</router-link></li>
                  <li><router-link to="/brochures">Brochures</router-link></li>
                  <li><router-link to="/presentations">Presentations</router-link></li>
                </ul>
              </li>
              <li><router-link to="/events">Events</router-link></li>
              <li class="w-subnav"><router-link to="/support">Support</router-link>
                <ul>
                  <li><router-link to="/support">Contact information</router-link></li>
                  <li><router-link to="/support/product-info">Product information</router-link></li>
                  <li><router-link to="/support/faq">FAQ</router-link></li>
                </ul>
              </li>
              <li class="search"><span><i class="fal fa-search cursor-pointer" v-on:click="showSearch()"></i></span></li>
              <li class="last"><span><i class="fal fa-user-md"></i></span>
                <ul>
                  <li><router-link to="/profile">Profile</router-link></li>
                  <li><router-link to="/login?logout=true">Log out</router-link></li>
                </ul>
              </li>
            </ul>
            <div id="mobile-nav">
              <ul>
                <li class="search"><span><i class="fal fa-search cursor-pointer" v-on:click="showSearch()"></i></span></li>
                <li class="profile"><span><i class="fal fa-user-md"></i></span>
                  <ul>
                    <li><router-link to="/profile">Profile</router-link></li>
                    <li><router-link to="/login?logout=true">Log out</router-link></li>
                  </ul>
                </li>
                <li class="show-menu">
                  <div id="show-menu" :class="{ open: show_mm }" v-on:click="toggleMenu"><span class="bar"></span><span class="bar"></span><span class="bar"></span></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="header-wrap" v-if="!$parent.loggedin && $parent.loginmenu">
      <div class="container" >
        <div class="row">
          <div class="col-12">
            <router-link to="/"><span id="logo">Smart Healing Center</span></router-link>
            <ul class="primary-nav mobile">
              <li class="no-active"><router-link to="/login">Login / register</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <router-view/>

    <div id="footer-wrap" :class="{ 'small' : $parent.main }">
      <div class="container">
        <div class="row" v-if="!$parent.main">
          <div class="col-12 col-md-3 pb-4">
            <span class="footer-logo"></span>
          </div>
          <div class="col-12 col-md-9">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-3 pb-2">
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pb-2">
                <h4>Material database</h4>
                <ul>
                  <li><router-link to="/videos">Videos</router-link></li>
                  <li><router-link to="/publications">Publications</router-link></li>
                  <li><router-link to="/cases">Cases</router-link></li>
                  <li><router-link to="/brochures">Brochures</router-link></li>
                  <li><router-link to="/presentations">Presentations</router-link></li>
                </ul>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pb-2">
                <h4>Events</h4>
                <ul>
                  <li><router-link to="/events">See our events</router-link></li>
                </ul>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pb-2">
                <h4>Support</h4>
                <ul>
                  <li><router-link to="/support">Contact information</router-link></li>
                  <li><router-link to="/support/product-info">Product information</router-link></li>
                  <li><router-link to="/support/faq">FAQ</router-link></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-9 offset-md-3 pt-5">
            <p class="small">Copyright © 2021 Bonalive Biomaterials Ltd.<br/>
              For Bonalive® products sold in the USA, please visit: <a href="https://www.bonalive.com/en-us" target="_blank">https://www.bonalive.com/en-us</a><br/>
              <a href="https://www.bonalive.com/privacy-policy/#acc-5" target="_blank">Bonalive® Privacy Policy</a>
            </p>
          </div>
        </div>
        <div class="row" v-if="$parent.main">
          <div class="col-12">
            <p class="small">Copyright © 2021 Bonalive Biomaterials Ltd.<br/>
              For Bonalive® products sold in the USA, please visit: <a href="https://www.bonalive.com/en-us" target="_blank">https://www.bonalive.com/en-us</a><br/>
              <a href="https://www.bonalive.com/privacy-policy/#acc-5" target="_blank">Bonalive® Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="show_search">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Search</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="show_search = false">
                    <span aria-hidden="true" >&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="search float-none">
                        <input type="text" ref="searchfield" class="form-control" v-model="term" placeholder="Enter your search keyword" v-on:keyup.enter="gotoSearch()">
                        <button class="btn search" type="submit" v-on:click="gotoSearch()"><i class="far fa-search"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
export default {
  data: function () {
    return {
      show_mm:false,
      show_search: false,
      term: '',
    }
  },
  methods: {
    toggleMenu: function () {
      var vm = this;
      if(vm.show_mm === false) {
        vm.show_mm = true;
      } else {
        vm.show_mm = false;
      }
    },
    showSearch: function() {
      this.show_search = true;
      setTimeout(() => { this.$refs.searchfield.focus() }, 300)
    },
    gotoSearch: function() {
      this.$router.push({ path: 'search', query: { s: this.term } })
      this.term = '';
      this.show_search = false;
    }
  }
}
</script>
