<template>
  <div class="trial-fp">
    <div class="green-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-4">
            <h1>Register today <br class="d-none d-xl-block" />for free!</h1>
          </div>
          <div class="col-12 col-lg-7 offset-lg-1 pt-4">
            <p><strong>Smart Healing Center</strong> is a complete platform, from surgeon to surgeon, facilitated by Bonalive Biomaterials. The platform provides you with patient cases, publications, and peer support in the use of S53P4 bioactive glass, onsite and online.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="indication-wrap landing-wrap">
      <div class="container">

        <div class="row">
          <div class="col-12 col-md-6 skele-col">
            <div class="skeleton-wrap">
              <div class="skeleton" v-if="type == 'classic'">
                <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton" :class="{ active: active == 'skeleton' }" />
                <img v-if="0" :src="[ '/assets/img/skeleton_chest.png' ]" class="chest" :class="{ active: active == 'chest' }" />
                <img :src="[ '/assets/img/skeleton_legs.png' ]" class="legs" :class="{ active: active == 'legs' }" />
                <img :src="[ '/assets/img/skeleton_arms.png' ]" class="hands" :class="{ active: active == 'arms' }" />
                <img :src="[ '/assets/img/skeleton_head.png' ]" class="head" :class="{ active: active == 'head' }" />
                <img :src="[ '/assets/img/skeleton_spine.png' ]" class="spine" :class="{ active: active == 'spine' }" />
                <div class="head" title="ENT and CMF" v-on:click="setActive('head')"></div>
                <div v-if="0" class="chest" title="Chest" v-on:click="setActive('chest')"></div>
                <div class="spine" title="Spine" v-on:click="setActive('spine')"></div>
                <div class="handl" title="Hand &amp; Upper extremity" v-on:click="setActive('arms')"></div>
                <div class="handr" title="Hand &amp; Upper extremity" v-on:click="setActive('arms')"></div>
                <div class="legs" title="Pelvis &amp; Lower extremity" v-on:click="setActive('legs')"></div>
              </div>
              <div class="skeleton" v-if="type == 'boneinfection'">
                <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton active" />
                <img :src="[ '/assets/img/skeleton_anim_boneinfection.png' ]" class="skeleton-anim" />
                <router-link class="spine" title="Spine" :to="{ path: '/trial/boneinfection', query: { area: 'spine' }}"></router-link>
                <router-link class="handl" title="Hand &amp; Upper extremity" :to="{ path: '/trial/boneinfection', query: { area: 'upperextremity' }}"></router-link>
                <router-link class="handr" title="Hand &amp; Upper extremity" :to="{ path: '/trial/boneinfection', query: { area: 'upperextremity' }}"></router-link>
                <router-link class="legs" title="Pelvis &amp; Lower extremity" :to="{ path: '/trial/boneinfection', query: { area: 'lowerextremity' }}"></router-link>
              </div>
              <div class="skeleton" v-if="type == 'bbt'">
                <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton active" />
                <img :src="[ '/assets/img/skeleton_anim_bonetumor.png' ]" class="skeleton-anim" />
                <router-link class="handl" title="Hand &amp; Upper extremity" :to="{ path: '/trial/bbt', query: { area: 'upperextremity' }}"></router-link>
                <router-link class="handr" title="Hand &amp; Upper extremity" :to="{ path: '/trial/bbt', query: { area: 'upperextremity' }}"></router-link>
                <router-link class="legs" title="Pelvis &amp; Lower extremity" :to="{ path: '/trial/bbt', query: { area: 'lowerextremity' }}"></router-link>
              </div>
              <div class="skeleton" v-if="type == 'mastoid'">
                <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton active" />
                <img :src="[ '/assets/img/skeleton_anim_mastoid.png' ]" class="skeleton-anim" />
                <router-link class="head" title="ENT and CMF" :to="{ path: '/trial/mastoid', query: { area: 'head' }}"></router-link>
              </div>
              <div class="skeleton" v-if="type == 'spine'">
                <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton active" />
                <img :src="[ '/assets/img/skeleton_anim_spine.png' ]" class="skeleton-anim" />
                <router-link class="spine" title="Spine" :to="{ path: '/trial/spine', query: { area: 'spine' }}"></router-link>
              </div>
              <div class="skeleton" v-if="type == 'trauma'">
                <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton active" />
                <img :src="[ '/assets/img/skeleton_anim_trauma.png' ]" class="skeleton-anim" />
                <router-link class="handl" title="Hand &amp; Upper extremity" :to="{ path: '/trial/trauma', query: { area: 'upperextremity' }}"></router-link>
                <router-link class="handr" title="Hand &amp; Upper extremity" :to="{ path: '/trial/trauma', query: { area: 'upperextremity' }}"></router-link>
                <router-link class="legs" title="Pelvis &amp; Lower extremity" :to="{ path: '/trial/trauma', query: { area: 'lowerextremity' }}"></router-link>
              </div>
              <div class="d-none">
                <img :src="[ '/assets/img/skeleton_anim_boneinfection.png' ]" rel="preload" />
                <img :src="[ '/assets/img/skeleton_anim_bonetumor.png' ]" rel="preload" />
                <img :src="[ '/assets/img/skeleton_anim_trauma.png' ]" rel="preload" />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 pt-5 mt-2 select-col">
            <h1 class="wc-title mb-2 cursor-pointer" v-on:click="setActive('skeleton')">Explore</h1>
            <p v-if="active == 'skeleton' && type == 'classic'" class="mt-4">Choose an indication area to see cases,<br/>videos, publications and more</p>
            <p v-if="type != 'classic'" class="w-icon mt-4" :class="{ visible: active == 'skeleton' }">Click the skeleton to specify<br/>the anatomical area <span class="touch-icon"></span></p>
            <div v-if="active == 'skeleton'" class="landing-links" :class="{ hidemobile: type != 'classic' }">
              <h1 class="wc-title mb-2 cursor-pointer mobile-only" v-on:click="setActive('skeleton')">Explore</h1>
              <p v-if="active == 'skeleton' && type == 'classic'" class="mt-4 mobile-only">Choose an indication area to see cases,<br/>videos, publications and more</p>
              <span class="close-link cursor-pointer d-none" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
              <ul>
                <li><router-link :to="{ path: '/trial/dbf', query: { area: 'lowerextremity' }}"  :class="{ inactive: (type != 'dbf' && type != 'classic') }">Diabetic foot osteomyelitis surgery</router-link></li>
                <li><a href="javascript:void(0)" v-on:click="setType('boneinfection')" :class="{ inactive: (type != 'boneinfection' && type != 'classic') }">Bone infection surgery</a></li>
                <li v-if="0"><a href="javascript:void(0)" v-on:click="setType('mastoid')" :class="{ inactive: (type != 'mastoid' && type != 'classic') }">Mastoid surgery</a></li>
                <li><router-link :to="{ path: '/trial/mastoid', query: { area: 'head' }}"  :class="{ inactive: (type != 'mastoid' && type != 'classic') }">Mastoid surgery</router-link></li>
                <li><a href="javascript:void(0)" v-on:click="setType('bbt')"  :class="{ inactive: (type != 'bbt' && type != 'classic') }">Benign bone tumor surgery</a></li>
                <li v-if="0"><a href="javascript:void(0)" v-on:click="setType('spine')">Spine fusion surgery</a></li>
                <li><router-link :to="{ path: '/trial/spine', query: { area: 'spine' }}"  :class="{ inactive: (type != 'spine' && type != 'classic') }">Spine fusion surgery</router-link></li>
                <li v-if="0"><a href="javascript:void(0)" v-on:click="setType('trauma')" :class="{ inactive: (type != 'trauma' && type != 'classic') }">Trauma surgery</a></li>
                <li><router-link :to="{ path: '/trial/trauma', query: { area: 'lowerextremity' }}"  :class="{ inactive: (type != 'trauma' && type != 'classic') }">Trauma surgery</router-link></li>
              </ul>
            </div>

            <div v-if="type == 'classic' && active == 'arms'" class="landing-links">
              <span class="close-link cursor-pointer" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
              <ul>
                <li><router-link :to="{ path: '/trial/boneinfection', query: { area: 'upperextremity' }}">Bone infection surgery</router-link></li>
                <li><router-link :to="{ path: '/trial/bbt', query: { area: 'upperextremity' }}">Benign bone tumor surgery</router-link></li>
                <li><router-link :to="{ path: '/trial/trauma', query: { area: 'upperextremity' }}">Trauma surgery</router-link></li>
              </ul>
            </div>

            <div v-if="type == 'classic' && active == 'legs'" class="landing-links">
              <span class="close-link cursor-pointer" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
              <ul>
                <li><router-link :to="{ path: '/trial/dbf', query: { area: 'lowerextremity' }}">Diabetic foot osteomyelitis surgery</router-link></li>
                <li><router-link :to="{ path: '/trial/boneinfection', query: { area: 'lowerextremity' }}">Bone infection surgery</router-link></li>
                <li><router-link :to="{ path: '/trial/bbt', query: { area: 'lowerextremity' }}">Benign bone tumor surgery</router-link></li>
                <li><router-link :to="{ path: '/trial/trauma', query: { area: 'lowerextremity' }}">Trauma surgery</router-link></li>
              </ul>
            </div>

            <div v-if="type == 'classic' && active == 'chest'" class="landing-links">
              <span class="close-link cursor-pointer" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
              <ul>
                <li><router-link to="/trial/boneinfection">Bone infection surgery</router-link></li>
                <li><router-link to="/trial/bbt">Benign bone tumor surgery</router-link></li>
                <li><router-link to="/trial/mastoid">Mastoid surgery</router-link></li>
                <li><router-link to="/trial/spine">Spine fusion surgery</router-link></li>
                <li><router-link to="/trial/trauma">Trauma surgery</router-link></li>
              </ul>
            </div>

            <div v-if="type == 'classic' && active == 'spine'" class="landing-links">
              <span class="close-link cursor-pointer" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
              <ul>
                <li><router-link :to="{ path: '/trial/spine', query: { area: 'spine' }}">Spine fusion surgery</router-link></li>
                <li><router-link :to="{ path: '/trial/boneinfection', query: { area: 'spine' }}">Bone infection surgery</router-link></li>
              </ul>
            </div>

            <div v-if="type == 'classic' && active == 'head'" class="landing-links">
              <span class="close-link cursor-pointer" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
              <ul>
                <li><router-link :to="{ path: '/trial/mastoid', query: { area: 'head' }}">Mastoid surgery</router-link></li>
              </ul>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="green-wrap">
      <div class="container py-4">
        <div class="row">
          <div class="col-12 text-center pb-2">
            <h1>What you get</h1>
          </div>
          <div class="col-12 col-lg-6 col-xl-5 offset-xl-1 pt-4">
            <div class="list-item"><div class="icon"><img :src="[ '/assets/img/Bonalive_Pictogram_RGB-11.png' ]" /></div> <span>Access to <strong>200+ article abstracts</strong></span></div>
            <div class="list-item"><div class="icon"><img :src="[ '/assets/img/Bonalive_Pictogram_RGB-25.png' ]" /></div> <span>View <strong>60+ patient cases</strong> and upload your own</span></div>
            <div class="list-item"><div class="icon"><img :src="[ '/assets/img/Bonalive_Pictogram_RGB-06.png' ]" /></div> <span>Register to and attend <strong>webinars and courses</strong></span></div>
          </div>
          <div class="col-12 col-lg-6 col-xl-5 pb-4 pt-lg-4">
            <div class="list-item"><div class="icon"><img :src="[ '/assets/img/Bonalive_Pictogram_RGB-04.png' ]" /></div> <span><strong>Surgical techniques</strong> and videos</span></div>
            <div class="list-item"><div class="icon"><img :src="[ '/assets/img/Products_Pictogram.png' ]" /></div> <span>View <strong>product information</strong> and brochures</span></div>
            <div class="list-item"><div class="icon"><img :src="[ '/assets/img/Consultant_Pictogram.png' ]" /></div> <span> Distributor contact details<strong> and support</strong></span></div>
          </div>
          <div class="col-12 text-center py-4">
            <router-link to="/login" class="btn btn-white btn-lg">Register now</router-link>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  data: function () {
    return {
      type: 'classic',
      active: 'skeleton',
      loading: true
    }
  },
  created: function () {

  },
  methods: {
    setType: function(type) {
      this.type = type;
    },
    setActive: function(id) {
      this.type = 'classic';
      this.active = id;
    }
  },
  mounted() {
  }
}
</script>
