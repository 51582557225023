<template>
  <div class="bg-wrap">
    <div class="container">
      <div class="loading results" v-if="loading"></div>

      <div v-if="!loading && changepass" class="my-4">
        <div class="row">
          <div class="col-12">
            <form class="profile pass" @submit.prevent="updatePass">
              <h1>Change password</h1>

              <div class="form-group row pt-5">
                <label class="col-sm-3 col-form-label">Current <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="icon icon-lock"><input type="password" class="form-control" v-model="password.old" required /></div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">New <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="icon icon-lock"><input type="password" class="form-control" v-model="password.new" required /></div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Confirm new <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="icon icon-lock"><input type="password" class="form-control" v-model="password.confirm" required /></div>
                </div>
              </div>

              <div class="alert alert-primary my-3" role="alert" v-if="pass_error" v-html="pass_error"></div>

              <button type="submit" class="btn btn-primary btn-full arrow-right">Change password</button>

              <p class="mb-0"><a href="#" v-on:click.stop.prevent="changepass = false">Back to profile</a></p>
            </form>
          </div>
        </div>
      </div>

      <div v-if="!loading && unregister" class="my-4">
        <div class="row">
          <div class="col-12">
            <form class="profile pass" @submit.prevent="sendUnregister">
              <h1>Unregister</h1>

              <p>I acknowledge that all my previous searches within this platform will be lost and that this action cannot be reversed.</p>

              <div class="check-wrap">
                <div class="custom-control custom-checkbox"><input type="checkbox" id="terms" v-model="unregister_accept" required class="custom-control-input">
                  <label for="terms" class="custom-control-label">I agree. <span class="green">*</span></label>
                </div>
              </div>

              <div class="alert alert-primary my-3" role="alert" v-if="unregister_error" v-html="unregister_error"></div>

              <button type="submit" class="btn btn-primary btn-full arrow-right">Unregister</button>

              <p class="mb-0"><a href="#" v-on:click.stop.prevent="unregister = false">Back to profile</a></p>
            </form>
          </div>
        </div>
      </div>


      <div v-if="!loading && !changepass && !unregister" class="my-4">
        <div class="row">
          <div class="col-12">
            <form class="profile connect" @submit.prevent="updateInfo">
              <h1>Update profile</h1>
              <div class="form-group row">
                <div class="col-6">
                  <p class="mb-0"><a href="#" v-on:click.stop.prevent="changepass = true">Change password</a></p>
                </div>
                <div class="col-6">
                  <p class="mb-0"><a href="#" v-on:click.stop.prevent="unregister = true">Unregister</a></p>
                </div>
              </div>

              <div class="form-group row pt-5">
                <label class="col-sm-3 col-form-label">Title:</label>
                <div class="col-sm-9">
                  <div class="icon icon-user"><select class="form-control" v-model="profile.title">
                    <option value="">Select title</option>
                    <option v-for="option in titles" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
                  </select></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Name: <span class="green">*</span></label>
                <div class="col-6 col-sm-4 pr-0">
                  <div class="icon icon-user"><input type="text" class="form-control" v-model="profile.firstname" placeholder="First" required /></div>
                </div>
                <div class="col-6 col-sm-5">
                  <input type="text" class="form-control" v-model="profile.lastname" placeholder="Last" required />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Profession: <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="icon icon-stethoscope"><select class="form-control" v-model="profile.profession" required>
                    <option value="">Select profession</option>
                    <option v-for="option in professions" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
                  </select></div>
                </div>
              </div>

              <div class="form-group row" v-if="0">
                <label class="col-sm-3 col-form-label">Street address: <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="icon icon-mapmarker"><input type="text" class="form-control" v-model="profile.address" /></div>
                </div>
              </div>

              <div class="form-group row" v-if="0">
                <label class="col-sm-3 col-form-label">City: <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="icon icon-mapmarker"><input type="text" class="form-control" v-model="profile.city" /></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Postal code: <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="icon icon-mapmarker"><input type="text" class="form-control" v-model="profile.zip" required /></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Country: <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="icon icon-globe"><select class="form-control" v-model="profile.country" required>
                    <option v-for="option in countries" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
                  </select></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Hospital: </label>
                <div class="col-sm-9">
                  <div class="icon icon-hospital"><input type="text" class="form-control" v-model="profile.hospital" /></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">E-mail: <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="icon icon-envelope"><input type="email" class="form-control icon icon-envelope" v-model="profile.email" required /></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Phone: <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="icon icon-phone"><input type="text" class="form-control" v-model="profile.phone" required /></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Area of interest: <span class="green">*</span></label>
                <div class="col-sm-9">
                  <div class="check-wrap checkbox-small">
                    <div v-for="(option, index) in indications" class="custom-control custom-checkbox"  :key="option.value">
                      <input type="checkbox" class="custom-control-input" :id="[ 'exp-' + index ]" v-model="profile.expertise" :value="option.value" >
                      <label class="custom-control-label" :for="[ 'exp-' + index ]">{{ option.text }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">ResearchGate: </label>
                <div class="col-sm-9">
                  <div class="icon icon-rgate"><input type="text" class="form-control" v-model="profile.researchgate" /></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">LinkedIn: </label>
                <div class="col-sm-9">
                  <div class="icon icon-linkedin"><input type="text" class="form-control" v-model="profile.linkedin" /></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Background: </label>
                <div class="col-sm-9">
                  <textarea type="text" class="form-control" v-model="profile.background" placeholder="Here you can write about your educational and professional background."></textarea>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-form-label">Used Bonalive® bioactive glass before?</label>
                <div class="col-12">

                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="used1" v-model="profile.usedproducts" value="yes" class="custom-control-input">
                    <label class="custom-control-label" for="used1">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="used2" v-model="profile.usedproducts" value="no" class="custom-control-input">
                    <label class="custom-control-label" for="used2">No</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-form-label">Choose your avatar <span class="green">*</span></label>
                <div class="col-12 avatar-select">

                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="avatar1" v-model="profile.avatar" value="1" class="custom-control-input">
                    <label class="custom-control-label avatar avatar-1" for="avatar1"></label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="avatar2" v-model="profile.avatar" value="2" class="custom-control-input">
                    <label class="custom-control-label avatar avatar-2" for="avatar2"></label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="avatar3" v-model="profile.avatar" value="3" class="custom-control-input">
                    <label class="custom-control-label avatar avatar-3" for="avatar3"></label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="avatar4" v-model="profile.avatar" value="4" class="custom-control-input">
                    <label class="custom-control-label avatar avatar-4" for="avatar4"></label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="avatar5" v-model="profile.avatar" value="5" class="custom-control-input">
                    <label class="custom-control-label avatar avatar-5" for="avatar5"></label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="avatar6" v-model="profile.avatar" value="6" class="custom-control-input">
                    <label class="custom-control-label avatar avatar-6" for="avatar6"></label>
                  </div>

                </div>
              </div>

              <div class="alert alert-primary my-3" role="alert" v-if="error" v-html="error"></div>

              <button type="submit" class="btn btn-primary btn-full arrow-right">Update</button>

            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loading: true,
      error: false,
      pass_error: false,
      unregister_error: false,
      changepass: false,
      countries: this.shc_countries,
      indications: this.shc_indications,
      titles: this.shc_titles,
      professions: this.shc_professions,
      unregister: false,
      unregister_accept: false,
      password: {
        old: '',
        new: '',
        confirm: ''
      },
      profile: {
        title: '',
        firstname: '',
        lastname: '',
        profession: '',
        address: '',
        city: '',
        zip: '',
        country: '',
        phone: '',
        hospital: '',
        email: '',
        expertise: [],
        researchgate: '',
        linkedin: '',
        background: '',
        usedproducts: '',
        avatar: 1,
      },
      sending: false,
    }
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function() {
      var vm = this;
      vm.loading = true;
      this.axios.get('/api/profile', { withCredentials: true })
      .then(function (response) {
        vm.loading = false;
        vm.profile = response.data.object;

      })
      .catch(function () {
        vm.$router.push('/');
      })
    },
    updateInfo: function() {
      var vm = this;
      vm.error = 'Loading...';
      this.axios.put('/api/profile', this.qs.stringify(vm.profile), { withCredentials: true })
      .then(function (response) {
        if(response.data.success) {
          vm.error = 'Profile updated!';
        } else {
          var tmp = []
          Object.keys(response.data.errors).forEach(function(key){ tmp.push(response.data.errors[key]); });
          vm.error = tmp.join("<br>", tmp);
        }
      })
      .catch(function (error) {
        vm.error = error;
      })
    },
    updatePass: function() {
      var vm = this;
      vm.pass_error = 'Loading...';
      this.axios.post('/api/profile/password', this.qs.stringify(vm.password), { withCredentials: true })
      .then(function (response) {
        if(response.data.success) {
          vm.pass_error = 'Password updated!';
          vm.password.old = '';
          vm.password.new = '',
          vm.password.confirm = '';
        } else {
          vm.pass_error = response.data.message;
        }
      })
      .catch(function (error) {
        vm.error = error;
      })
    },
    sendUnregister: function() {
      var vm = this;
      vm.unregister_error = '';
      if(vm.unregister_accept) {
        vm.unregister_error = 'Loading...';
        this.axios.post('/api/profile/unregister', 'accept=' + vm.unregister_accept, { withCredentials: true })
        .then(function (response) {
          if(response.data.success) {
            vm.$parent.loggedin = false;
            vm.$parent.userid = 0;
            vm.$parent.username = 'anonymous';
            alert('Unregistration complete!');
            vm.$router.push('/');
          } else {
            vm.unregister_error = response.data.message;
          }
        })
        .catch(function (error) {
          vm.error = error;
        })
      }
    }
  }
}
</script>
