import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '../views/404.vue'
import Login from '../views/Login.vue'
import Profile from '../views/Profile.vue'
import Landing from '../views/Landing.vue'
import Indication from '../views/Indication.vue'
import LandingTrial from '../views/LandingTrial.vue'
import LandingTrialIndication from '../views/LandingTrialIndication.vue'
import Search from '../views/Search.vue'
import Events from '../views/Events.vue'
import Faq from '../views/Faq.vue'
import Support from '../views/Support.vue'
import Products from '../views/Products.vue'
import Publications from '../views/DataPublications.vue'
import Cases from '../views/DataCases.vue'
import Videos from '../views/DataVideos.vue'
import Brochures from '../views/DataBrochures.vue'
import Presentations from '../views/DataPresentations.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: LandingTrial },
  { path: '/trial/:id', name: 'Trial', component: LandingTrialIndication },
  { path: '/login', name: 'Login', component: Login },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/indication', name: 'Indication list', component: Landing },
  { path: '/indication/:id', name: 'Single indication', component: Indication },
  { path: '/search', name: 'Search', component: Search },
  { path: '/events', name: 'Events', component: Events },
  { path: '/publications', name: 'Publications', component: Publications },
  { path: '/cases', name: 'Cases', component: Cases },
  { path: '/videos', name: 'Videos', component: Videos },
  { path: '/support', name: 'Support', component: Support },
  { path: '/support/faq', name: 'Faq', component: Faq },
  { path: '/support/product-info', name: 'Product info', component: Products },
  { path: '/brochures', name: 'Brochures', component: Brochures },
  { path: '/presentations', name: 'Presentations', component: Presentations },
  { path: '*', name: '404', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
