<template>
  <div class="indication-wrap landing-wrap">

    <div class="container">
      <div class="row">

        <div class="col-12 col-md-6 skele-col">
          <div class="skeleton-wrap">
            <div class="skeleton" v-if="type == 'classic'">
              <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton" :class="{ active: active == 'skeleton' }" />
              <img v-if="0" :src="[ '/assets/img/skeleton_chest.png' ]" class="chest" :class="{ active: active == 'chest' }" />
              <img :src="[ '/assets/img/skeleton_legs.png' ]" class="legs" :class="{ active: active == 'legs' }" />
              <img :src="[ '/assets/img/skeleton_arms.png' ]" class="hands" :class="{ active: active == 'arms' }" />
              <img :src="[ '/assets/img/skeleton_head.png' ]" class="head" :class="{ active: active == 'head' }" />
              <img :src="[ '/assets/img/skeleton_spine.png' ]" class="spine" :class="{ active: active == 'spine' }" />
              <div class="head" title="ENT and CMF" v-on:click="setActive('head')"></div>
              <div v-if="0" class="chest" title="Chest" v-on:click="setActive('chest')"></div>
              <div class="spine" title="Spine" v-on:click="setActive('spine')"></div>
              <div class="handl" title="Hand &amp; Upper extremity" v-on:click="setActive('arms')"></div>
              <div class="handr" title="Hand &amp; Upper extremity" v-on:click="setActive('arms')"></div>
              <div class="legs" title="Pelvis &amp; Lower extremity" v-on:click="setActive('legs')"></div>
            </div>
            <div class="skeleton" v-if="type == 'boneinfection'">
              <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton active" />
              <img :src="[ '/assets/img/skeleton_anim_boneinfection.png' ]" class="skeleton-anim" />
              <router-link class="spine" title="Spine" :to="{ path: '/indication/boneinfection', query: { area: 'spine' }}"></router-link>
              <router-link class="handl" title="Hand &amp; Upper extremity" :to="{ path: '/indication/boneinfection', query: { area: 'upperextremity' }}"></router-link>
              <router-link class="handr" title="Hand &amp; Upper extremity" :to="{ path: '/indication/boneinfection', query: { area: 'upperextremity' }}"></router-link>
              <router-link class="legs" title="Pelvis &amp; Lower extremity" :to="{ path: '/indication/boneinfection', query: { area: 'lowerextremity' }}"></router-link>
            </div>
            <div class="skeleton" v-if="type == 'bbt'">
              <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton active" />
              <img :src="[ '/assets/img/skeleton_anim_bonetumor.png' ]" class="skeleton-anim" />
              <router-link class="handl" title="Hand &amp; Upper extremity" :to="{ path: '/indication/bbt', query: { area: 'upperextremity' }}"></router-link>
              <router-link class="handr" title="Hand &amp; Upper extremity" :to="{ path: '/indication/bbt', query: { area: 'upperextremity' }}"></router-link>
              <router-link class="legs" title="Pelvis &amp; Lower extremity" :to="{ path: '/indication/bbt', query: { area: 'lowerextremity' }}"></router-link>
            </div>
            <div class="skeleton" v-if="type == 'mastoid'">
              <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton active" />
              <img :src="[ '/assets/img/skeleton_anim_mastoid.png' ]" class="skeleton-anim" />
              <router-link class="head" title="ENT and CMF" :to="{ path: '/indication/mastoid', query: { area: 'head' }}"></router-link>
            </div>
            <div class="skeleton" v-if="type == 'spine'">
              <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton active" />
              <img :src="[ '/assets/img/skeleton_anim_spine.png' ]" class="skeleton-anim" />
              <router-link class="spine" title="Spine" :to="{ path: '/indication/spine', query: { area: 'spine' }}"></router-link>
            </div>
            <div class="skeleton" v-if="type == 'trauma'">
              <img :src="[ '/assets/img/skeleton.png' ]" class="skeleton active" />
              <img :src="[ '/assets/img/skeleton_anim_trauma.png' ]" class="skeleton-anim" />
              <router-link class="handl" title="Hand &amp; Upper extremity" :to="{ path: '/indication/trauma', query: { area: 'upperextremity' }}"></router-link>
              <router-link class="handr" title="Hand &amp; Upper extremity" :to="{ path: '/indication/trauma', query: { area: 'upperextremity' }}"></router-link>
              <router-link class="legs" title="Pelvis &amp; Lower extremity" :to="{ path: '/indication/trauma', query: { area: 'lowerextremity' }}"></router-link>
            </div>
            <div class="d-none">
              <img :src="[ '/assets/img/skeleton_anim_boneinfection.png' ]" rel="preload" />
              <img :src="[ '/assets/img/skeleton_anim_bonetumor.png' ]" rel="preload" />
              <img :src="[ '/assets/img/skeleton_anim_trauma.png' ]" rel="preload" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 pt-5 mt-lg-5 select-col">
          <h1 class="wc-title mb-2 cursor-pointer" v-on:click="setActive('skeleton')">Explore</h1>
          <p v-if="active == 'skeleton' && type == 'classic'" class="mt-4">Choose an indication area to see cases,<br/>videos, publications and more</p>
          <p v-if="type != 'classic'" class="w-icon mt-4" :class="{ visible: active == 'skeleton' }">Click the skeleton to specify<br/>the anatomical area <span class="touch-icon"></span></p>
          <div v-if="active == 'skeleton'" class="landing-links" :class="{ hidemobile: type != 'classic' }">
            <h1 class="wc-title mb-2 cursor-pointer mobile-only" v-on:click="setActive('skeleton')">Explore</h1>
            <p v-if="active == 'skeleton' && type == 'classic'" class="mt-4 mobile-only">Choose an indication area to see cases,<br/>videos, publications and more</p>
            <span class="close-link cursor-pointer d-none" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
            <ul>
              <li><router-link :to="{ path: '/indication/dbf', query: { area: 'lowerextremity' }}"  :class="{ inactive: (type != 'dbf' && type != 'classic') }">Diabetic foot osteomyelitis surgery</router-link></li>
              <li><a href="javascript:void(0)" v-on:click="setType('boneinfection')" :class="{ inactive: (type != 'boneinfection' && type != 'classic') }">Bone infection surgery</a></li>
              <li v-if="0"><a href="javascript:void(0)" v-on:click="setType('mastoid')" :class="{ inactive: (type != 'mastoid' && type != 'classic') }">Mastoid surgery</a></li>
              <li><router-link :to="{ path: '/indication/mastoid', query: { area: 'head' }}"  :class="{ inactive: (type != 'mastoid' && type != 'classic') }">Mastoid surgery</router-link></li>
              <li><a href="javascript:void(0)" v-on:click="setType('bbt')"  :class="{ inactive: (type != 'bbt' && type != 'classic') }">Benign bone tumor surgery</a></li>
              <li v-if="0"><a href="javascript:void(0)" v-on:click="setType('spine')">Spine fusion surgery</a></li>
              <li><router-link :to="{ path: '/indication/spine', query: { area: 'spine' }}"  :class="{ inactive: (type != 'spine' && type != 'classic') }">Spine fusion surgery</router-link></li>
              <li v-if="0"><a href="javascript:void(0)" v-on:click="setType('trauma')" :class="{ inactive: (type != 'trauma' && type != 'classic') }">Trauma surgery</a></li>
              <li><router-link :to="{ path: '/indication/trauma', query: { area: 'lowerextremity' }}"  :class="{ inactive: (type != 'trauma' && type != 'classic') }">Trauma surgery</router-link></li>

            </ul>
          </div>

          <div v-if="type == 'classic' && active == 'arms'" class="landing-links">
            <span class="close-link cursor-pointer" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
            <ul>
              <li><router-link :to="{ path: '/indication/boneinfection', query: { area: 'upperextremity' }}">Bone infection surgery</router-link></li>
              <li><router-link :to="{ path: '/indication/bbt', query: { area: 'upperextremity' }}">Benign bone tumor surgery</router-link></li>
              <li><router-link :to="{ path: '/indication/trauma', query: { area: 'upperextremity' }}">Trauma surgery</router-link></li>
            </ul>
          </div>

          <div v-if="type == 'classic' && active == 'legs'" class="landing-links">
            <span class="close-link cursor-pointer" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
            <ul>
              <li><router-link :to="{ path: '/indication/dbf', query: { area: 'lowerextremity' }}">Diabetic foot osteomyelitis surgery</router-link></li>
              <li><router-link :to="{ path: '/indication/boneinfection', query: { area: 'lowerextremity' }}">Bone infection surgery</router-link></li>
              <li><router-link :to="{ path: '/indication/bbt', query: { area: 'lowerextremity' }}">Benign bone tumor surgery</router-link></li>
              <li><router-link :to="{ path: '/indication/trauma', query: { area: 'lowerextremity' }}">Trauma surgery</router-link></li>
            </ul>
          </div>

          <div v-if="type == 'classic' && active == 'chest'" class="landing-links">
            <span class="close-link cursor-pointer" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
            <ul>
              <li><router-link to="/indication/boneinfection">Bone infection surgery</router-link></li>
              <li><router-link to="/indication/bbt">Benign bone tumor surgery</router-link></li>
              <li><router-link to="/indication/mastoid">Mastoid surgery</router-link></li>
              <li><router-link to="/indication/spine">Spine fusion surgery</router-link></li>
              <li><router-link to="/indication/trauma">Trauma surgery</router-link></li>
            </ul>
          </div>

          <div v-if="type == 'classic' && active == 'spine'" class="landing-links">
            <span class="close-link cursor-pointer" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
            <ul>
              <li><router-link :to="{ path: '/indication/spine', query: { area: 'spine' }}">Spine fusion surgery</router-link></li>
              <li><router-link :to="{ path: '/indication/boneinfection', query: { area: 'spine' }}">Bone infection surgery</router-link></li>
            </ul>
          </div>

          <div v-if="type == 'classic' && active == 'head'" class="landing-links">
            <span class="close-link cursor-pointer" v-on:click="setActive('skeleton')"><i class="far fa-times"></i> Close</span>
            <ul>
              <li><router-link :to="{ path: '/indication/mastoid', query: { area: 'head' }}">Mastoid surgery</router-link></li>
            </ul>
          </div>
        </div>

      </div>
    </div>

    <div class="landing-slider">
      <div class="slider-wrap" v-if="slider_loading"><div class="loading results"></div></div>
      <div class="slider-wrap" v-if="!slider_loading">
        <swiper class="swiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in slides" :key="index" class="news-item">
            <a :href="item.link" target="_blank" v-on:click.stop="showContent(index, $event)">
              <div class="image" :style="{ backgroundImage: 'url(' + item.thumbnail + ')' }"></div>
              <span class="date"><i :class="item.icon"></i> {{ item.nice_date }}</span>
              <div class="intro" v-html="item.intro"></div>
            </a>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>

    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ modal_title }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModal = false">
                    <span aria-hidden="true" >&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12" v-html="modal_content">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
export default {
  data: function () {
    return {
      type: 'classic',
      active: 'skeleton',
      loading: true,
      slider_loading: true,
      plans: [],
      slides: [],
      swiperOptions: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
        breakpoints: {
          '540': { slidesPerView: 2, slidesPerGroup: 2 },
          '960': { slidesPerView: 3, slidesPerGroup: 3 }
        },
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      showModal: false,
      modal_title: '',
      modal_content: ''
    }
  },
  created: function () {
    var vm = this;

    this.axios.get('/api/news?limit=9', { withCredentials: true })
    .then(function (response) {
      if(typeof response.data.results != 'undefined') {
        vm.slides = response.data.results;
        vm.slider_loading = false;
      }
    }).catch(function () { })

  },
  methods: {
    setType: function(type) {
      this.type = type;
    },
    setActive: function(id) {
      this.type = 'classic';
      this.active = id;
    },
    showContent: function (index, event) {
      var vm = this;
      if(vm.slides[index].content != '') {

        vm.modal_title = vm.slides[index].nice_date;
        vm.modal_content = vm.slides[index].content;
        vm.showModal = true;

        event.preventDefault();
      }
    }
  },
  mounted() {
  }
}
</script>
