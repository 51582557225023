<template>
  <div class="bg-wrap">

    <div class="container" v-if="view === false">
      <div id="filter-items" class="filter-items">
        <div><h4>Filter by</h4></div>
        <div>
          <select v-model="filters.type" class="form-control custom-select">
            <option value="">Event type</option>
            <option value="congress">Congress</option>
            <option value="course">Course</option>
            <option value="social-media-campaign">Social media campaign</option>
            <option value="webinar">Webinar</option>
          </select>
        </div>
        <div>
          <select v-model="filters.country" class="form-control custom-select" style="width:95px;">
            <option value="">Location</option>
            <option v-for="option in countries" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
        <div>
          <label class="basic">From</label>
        </div>
        <div class="date">
          <v-date-picker v-model="filters.start_date">
            <template v-slot="{ inputValue, inputEvents }">
              <input class="form-control" :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </div>
        <div class="ml-3">
          <div class="custom-control custom-checkbox pt-2">
            <input type="checkbox" class="custom-control-input" v-model="filters.expertise" id="myexp">
            <label class="custom-control-label" for="myexp">Only my area of interest</label>
          </div>
        </div>
      </div>

      <div class="loading results" v-if="loading"></div>
      <div v-if="!loading" class="event-list">
        <div class="row">
          <div v-for="(item, index) in items" :key="item.id" class="col-12 col-md-6 col-lg-4 mb-4">
            <div class="event">
              <span class="badge top user" v-if="item.user_created">Distributor Event</span>
              <span class="badge top tag" v-if="item.tag != ''">{{ item.tag }}</span>
              <span class="badge live" v-if="item.live">Live now</span>
              <img class="d-block" :src="item.thumbnail" v-if="!item.video"/>
              <div class="embed-responsive embed-responsive-16by9" v-if="item.video" v-html="item.video"></div>
              <h3>{{ item.title }}</h3>
              <p class="event-type" :class="item.type">{{ item.type_nice }}</p>
              <p><span class="location">{{ item.location }}, {{ item.country_nice }}</span><span class="date">{{ item.start }} <span v-if="item.end != item.start"> - {{ item.end }}</span></span><span class="display-time" v-if="item.display_time != ''">{{ item.display_time }}</span></p>
              <div class="description" v-html="item.description"></div>

              <a class="btn more-info" :href="item.link" target="_blank" v-if="item.info1 == '' && item.link">More information</a>
              <a class="btn btn-sm registration" :href="item.reglink" target="_blank" v-if="item.info1 == '' && item.reglink != '' && item.remaining != false">{{ item.remaining }}</a>

              <a v-if="!item.live && item.info1 != ''" class="btn more-info" href="#" v-on:click.stop.prevent="view = index">Show event</a>
              <a v-if="item.live" class="btn more-info live" :href="[ '/zoom.html?id=' + item.id ]" ><i class="fas fa-presentation mr-2"></i> Live now</a>
              <p v-if="item.live && item.zoom_url != ''" class="small m-2 text-center">Not working? Open in the Zoom application, <a :href="item.zoom_url" target="_blank">click here</a>.</p>

              <div class="hls mt-4">
                <div class="hl mb-2" v-if="item.hlcount[1] > 0" :class="{ open: item.open[1] }">
                  <h5 v-on:click="showhl(index, 1)">Highlights</h5>
                  <div v-for="(hl) in item.hls" :key="hl.id" :class="{ 'd-none': hl.type != 1 }">
                    <span class="time">{{ hl.start_date }}</span>
                    <span class="hl-title">{{ hl.title }}</span>
                    <div class="hl-desc" v-html="hl.description"></div>
                    <span class="hl-loc">{{ hl.location }}</span>
                  </div>
                </div>
                <div class="hl mb-2" v-if="item.hlcount[2] > 0" :class="{ open: item.open[2] }">
                  <h5 v-on:click="showhl(index, 2)">Networking</h5>
                  <div v-for="(hl) in item.hls" :key="hl.id" :class="{ 'd-none': hl.type != 2 }">
                    <span class="time">{{ hl.start_date }}</span>
                    <span class="hl-title">{{ hl.title }}</span>
                    <div class="hl-desc" v-html="hl.description"></div>
                    <span class="hl-loc">{{ hl.location }}</span>
                  </div>
                </div>
                <div class="hl mb-2" v-if="item.hlcount[3] > 0" :class="{ open: item.open[3] }">
                  <h5 v-on:click="showhl(index, 3)">Workshops</h5>
                  <div v-for="(hl) in item.hls" :key="hl.id" :class="{ 'd-none': hl.type != 3 }">
                    <span class="time">{{ hl.start_date }}</span>
                    <span class="hl-title">{{ hl.title }}</span>
                    <div class="hl-desc" v-html="hl.description"></div>
                    <span class="hl-loc">{{ hl.location }}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="alert alert-primary" role="alert" v-if="!loading && items.length == 0">No events found!</div>
        <ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 0">
          <li v-for="n in pages" class="page-item" :class="[page == (n-1) ? 'active' : '']" :key="n"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
        </ul>
      </div>
    </div>

    <div class="container" v-if="view !== false">
      <div class="row">
        <div class="col-12">
          <p class="mt-4"><a href="#" v-on:click.stop.prevent="view = false">Back to all events</a></p>
        </div>
      </div>
      <div class="row event event-info">

        <div class="col-12">
          <h1 class="event-main">{{ items[view].title }}</h1>
        </div>

        <div class="col-12 col-lg-7">

          <div class="main-info" v-html="items[view].info1"></div>

          <p>
            <span class="location">{{ items[view].location }}, {{ items[view].country_nice }}</span>
            <span class="date">{{ items[view].start }} <span v-if="items[view].end != items[view].start"> - {{ items[view].end }}</span></span>
            <span class="display-time" v-if="items[view].display_time != ''">{{ items[view].display_time }}</span>
            <span class="participants" v-if="items[view].participants != ''">{{ items[view].participants }}</span>
          </p>

          <div class="row" v-if="!items[view].live">
            <div class="col-12 col-md-6" v-if="items[view].link">
              <a class="btn more-info my-2" :href="items[view].link" target="_blank" >More information</a>
            </div>
          </div>

          <div class="row" v-if="items[view].live">
            <div class="col-12 col-md-6" v-if="items[view].zoom_id != ''">
              <button type="button" class="btn more-info my-2" v-on:click="openZoom(items[view].zoom_id, items[view].zoom_passcode)">Watch now</button>
            </div>
            <div class="col-12 col-md-6" v-if="items[view].zoom_url != ''">
              <a class="btn more-info my-2" :href="items[view].zoom_url" target="_blank" >Open in Zoom</a>
            </div>
          </div>

          <img class="d-block my-4" style="max-width:320px;" :src="items[view].info_imgs.level" v-if="items[view].info_imgs.level" />

          <div class="more-info" v-html="items[view].info2"></div>

          <div class="row" v-if="!items[view].live">
            <div class="col-12 col-md-6" v-if="items[view].reglink != '' && items[view].remaining != false">
              <a class="btn btn-sm registration my-2" :href="items[view].reglink" target="_blank" >{{ items[view].remaining }}</a>
            </div>
          </div>

        </div>
        <div class="col-12 col-lg-4 offset-lg-1">

          <div class="persons open noafter" v-if="items[view].persons.length > 0">
            <h4 class="mt-0">Speaker(s)</h4>
            <div class="row">
              <div class="col-6 person" v-for="(person, index) in items[view].persons" :key="index">
                <div class="image"><img :src="person.img" /></div>
                <p>{{ person.name }}<br /><span class="hospital">{{ person.hospital }}</span></p>
              </div>
            </div>
          </div>

          <div class="hls" v-if="0">
            <div class="hl mb-2" v-if="items[view].hlcount[1] > 0" :class="{ open: items[view].open[1] }">
              <h5 v-on:click="showhl(view, 1)">Highlights</h5>
              <div v-for="(hl) in items[view].hls" :key="hl.id" :class="{ 'd-none': hl.type != 1 }">
                <span class="time">{{ hl.start_date }}</span> {{ hl.title }}
              </div>
            </div>
            <div class="hl mb-2" v-if="items[view].hlcount[2] > 0" :class="{ open: items[view].open[2] }">
              <h5 v-on:click="showhl(view, 2)">Networking</h5>
              <div v-for="(hl) in items[view].hls" :key="hl.id" :class="{ 'd-none': hl.type != 2 }">
                <span class="time">{{ hl.start_date }}</span> {{ hl.title }}
              </div>
            </div>
            <div class="hl mb-2" v-if="items[view].hlcount[3] > 0" :class="{ open: items[view].open[3] }">
              <h5 v-on:click="showhl(view, 3)">Workshops</h5>
              <div v-for="(hl) in items[view].hls" :key="hl.id" :class="{ 'd-none': hl.type != 3 }">
                <span class="time">{{ hl.start_date }}</span> {{ hl.title }}
              </div>
            </div>
          </div>

          <img class="d-block my-4" :src="items[view].thumbnail" v-if="!items[view].video"/>
          <div class="embed-responsive embed-responsive-16by9 my-4" v-if="items[view].video" v-html="items[view].video"></div>

          <div class="dropdown" v-if="items[view].filelinks">
            <button class="btn files dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">attachments</button>
            <div class="dropdown-menu dropdown-menu-right">
              {{ items[view].filelinks }}
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loading: true,
      view: false,
      sending: false,
      search: '',
      countries: this.shc_countries,
      filters: {
        start_date: new Date(),
        type: '',
        country: '',
        expertise: false
      },
      items: [],
      visible: 0,
      perpage: 12,
      pages: 0,
      page: 0,
      sort: 'start_date:asc',
      public: false,
      zoom_id: false,
      zoom_sig: false
    }
  },
  created: function () {
    var vm = this;
    this.debouncedLoad = this._.debounce(this.load, 500)
    vm.load();
  },
  methods: {
    load: function() {
      var vm = this;
      vm.loading = true;
      var sort = vm.sort.split(':');
      this.axios.get('/api/events?limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage) + '&sort=' + sort[0] + '&dir=' + sort[1] + '&search=' + vm.search + '&filters=' + JSON.stringify(vm.filters), { withCredentials: true })
      .then(function (response) {
        var items = response.data.results;

        Object.keys(items).forEach(function(key){
          items[key].open =  { 1: false, 2: false, 3: false, 4: false };
        });

        vm.items = items;
        vm.pages = Math.ceil(response.data.total / vm.perpage);
        vm.loading = false;
      })
      .catch(function () {
        vm.$router.push('/');
      })
    },
    showpage: function (id) {
      this.page = id;
    },
    showhl: function (index, hl) {
      if(this.items[index].open[hl] == true) this.items[index].open[hl] = false;
      else this.items[index].open[hl] = true;
    }
  },
  watch: {
    page: function () {
      this.load();
    },
    search: function () {
      this.debouncedLoad();
    },
    sort: function () {
      this.load();
    },
    filters: {
      handler: function () {
        if(this.page == 0) {
          this.load();
        } else {
          this.page = 0;
        }
      },
      deep: true
    }
  }
}
</script>
