<template>
  <div class="bg-wrap">
    <div class="container">
      <div id="filter-items" class="filter-items">
        <div><h4>Search result for</h4></div>
        <div><span class="term">{{ term }}</span></div>
        <div class="search float-none">
          <input type="text" class="form-control" v-model="term" placeholder="Enter your search keyword">
          <button class="btn search" type="submit"><i class="far fa-search"></i></button>
        </div>
      </div>
      <div class="loading results" v-if="loading"></div>
      <div class="row" v-if="results && !loading">

        <div class="col-12" v-if="s_articles.length == 0 && s_cases.length == 0 && s_brochures.length == 0 && s_videos.length == 0 && s_events.length == 0">
          <div class="alert alert-info" >No results found.<br/>If you don’t find what you’re looking for, try a different word or spelling in English.</div>
        </div>

        <div class="col-12" v-if="s_articles.length > 0">
          <h3>Publications</h3>
          <div class="alert alert-info" v-if="s_articles.length == 0">No articles found.</div>
          <div class="result-mh articles">
            <div class="row item article" v-for="(item) in s_articles" :key="item.id" :id="['BaArticle-' + item.id]">
              <div class="col-lg-1 image d-none d-lg-block"><router-link  target="_blank" :to="{ path: '/publications', query: { id: item.id } }"><img :src="item.thumbnail"></router-link></div>
              <div class="col-12 col-lg-4 title "><p><router-link class="text-decoration-none" target="_blank" :to="{ path: '/publications', query: { id: item.id } }"><strong>{{ item.title }}</strong></router-link></p></div>
              <div class="col-12 col-lg-3"><p>{{ item.author }}<br>{{ item.pub_year }}</p></div>
              <div class="col-6 col-lg-2 smaller"><p>{{ item.title_indication }}</p></div>
              <div class="col-6 col-lg-2 smaller last text-uppercase"><p>{{ item.study_type }}<br>{{ item.study_design }}<br>{{ item.ct_classification }}</p></div>
            </div>
          </div>
        </div>

        <div class="col-12" v-if="s_cases.length > 0">
          <h3>Cases</h3>
          <div class="alert alert-info" v-if="s_cases.length == 0">No cases found.</div>

          <div class="result-mh cases">
            <div class="row item case" v-for="(item) in s_cases" :key="item.id" :id="['BaCase-' + item.id]">
              <div class="col-12 case-info">
                <div class="row">
                  <div class="col-12 col-lg-2">
                    <router-link target="_blank" :to="{ path: '/cases', query: { id: item.id } }"><img class="case-thumb" :src="item.hcp_thumb" v-if="item.hcp_thumb" /></router-link>
                  </div>
                  <div class="col-12 col-lg-10 py-4">
                    <p><router-link class="text-decoration-none" target="_blank" :to="{ path: '/cases', query: { id: item.id } }"><strong>{{ item.title }}</strong></router-link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12" v-if="s_brochures.length > 0">
          <h3>Brochures</h3>
          <div class="alert alert-info" v-if="s_brochures.length == 0">No brochures found.</div>
          <div class="col-12 result-mh materials">
            <div class="row items">
              <div class="col-12 col-md-6 col-lg-4" v-for="(item) in s_brochures" :key="item.id">
                <div class="material brochure" :id="[ 'BaMaterial-' + item.id ]">
                  <h3><router-link class="text-decoration-none" target="_blank" :to="{ path: '/brochures', query: { id: item.id } }">{{ item.title }}</router-link></h3>
                  <div class="image">
                    <router-link target="_blank" :to="{ path: '/brochures', query: { id: item.id } }"><img :src="item.thumbnail"></router-link>
                  </div>
                  <p>{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12" v-if="s_videos.length > 0">
          <h3>Videos</h3>
          <div class="alert alert-info" v-if="s_videos.length == 0">No videos found.</div>
          <div class="col-12 result-mh materials">
            <div class="row items">
              <div class="col-12 col-md-6 col-lg-4" v-for="(item) in s_videos" :key="item.id">
                <div class="material video" :id="[ 'BaMaterial-' + item.id ]">
                  <h3><router-link  class="text-decoration-none" target="_blank" :to="{ path: '/videos', query: { id: item.id } }">{{ item.title }}</router-link></h3>
                  <div class="image">
                    <router-link target="_blank" :to="{ path: '/videos', query: { id: item.id } }"><img :src="item.thumbnail"></router-link>
                  </div>
                  <p>{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12" v-if="s_events.length > 0">
          <h3>Events</h3>
          <div class="alert alert-info" v-if="s_events.length == 0">No events found.</div>
          <div class="col-12 result-mh materials">
            <div class="row items">
              <div v-for="(item, index) in s_events" :key="item.id" class="col-12 col-md-6 col-lg-4 mb-4">
                <div class="event">
                  <span class="badge top user" v-if="item.user_created">Distributor Event</span>
                  <span class="badge top tag" v-if="item.tag != ''">{{ item.tag }}</span>
                  <img class="d-block" :src="item.thumbnail" v-if="!item.video"/>
                  <div class="embed-responsive embed-responsive-16by9" v-if="item.video" v-html="item.video"></div>
                  <h3>{{ item.title }}</h3>
                  <p class="event-type" :class="item.type">{{ item.type_nice }}</p>
                  <p><span class="location">{{ item.location }}, {{ item.country_nice }}</span><span class="date">{{ item.start }} <span v-if="item.end != item.start"> - {{ item.end }}</span></span></p>
                  <div class="description" v-html="item.description"></div>
                  <a class="btn more-info" :href="item.link" target="_blank" v-if="item.link">More information</a>
                  <a class="btn btn-sm registration" :href="item.reglink" target="_blank" v-if="item.reglink != '' && item.remaining != false">{{ item.remaining }}</a>
                  <div class="dropdown" v-if="item.filelinks">
                    <button class="btn files dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">attachments</button>
                    <div class="dropdown-menu dropdown-menu-right">
                      {{ item.filelinks }}
                    </div>
                  </div>
                  <div class="hls">
                    <div class="hl mb-2" v-if="item.hlcount[1] > 0" :class="{ open: item.open[1] }">
                      <h5 v-on:click="showhl(index, 1)">Highlights</h5>
                      <div v-for="(hl) in item.hls" :key="hl.id" :class="{ 'd-none': hl.type != 1 }">
                        <span class="time">{{ hl.start_date }}</span> {{ hl.title }}
                      </div>
                    </div>
                    <div class="hl mb-2" v-if="item.hlcount[2] > 0" :class="{ open: item.open[2] }">
                      <h5 v-on:click="showhl(index, 2)">Networking</h5>
                      <div v-for="(hl) in item.hls" :key="hl.id" :class="{ 'd-none': hl.type != 2 }">
                        <span class="time">{{ hl.start_date }}</span> {{ hl.title }}
                      </div>
                    </div>
                    <div class="hl mb-2" v-if="item.hlcount[3] > 0" :class="{ open: item.open[3] }">
                      <h5 v-on:click="showhl(index, 3)">Workshops</h5>
                      <div v-for="(hl) in item.hls" :key="hl.id" :class="{ 'd-none': hl.type != 3 }">
                        <span class="time">{{ hl.start_date }}</span> {{ hl.title }}
                      </div>
                    </div>
                  </div>
                  <div class="persons mt-3 open noafter" v-if="item.persons.length > 0" >
                    <h5 v-on:click="showhl(index, 4)">Clinical expert(s)</h5>
                    <div class="row">
                      <div class="col-6 person" v-for="person in item.persons" :key="person.id">
                        <div class="image"><img :src="person.img" /></div>
                        <p>{{ person.name }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loading: false,
      results: false,
      term: '',
      s_videos: [],
      s_articles: [],
      s_cases: [],
      s_brochures: [],
      s_events: []
    }
  },
  created: function () {
    this.debouncedSearch = this._.debounce(this.search, 500)

    if(typeof this.$route.query.s != 'undefined') {
      this.term = this.$route.query.s;
    }
  },
  methods: {
    search: function () {
      var vm = this;
      vm.loading = true;
      this.axios.get('/api/search?search=' + vm.term, { withCredentials: true })
      .then(function (response) {

        vm.s_videos = response.data.object.videos;
        vm.s_articles = response.data.object.articles;
        vm.s_cases = response.data.object.cases;
        vm.s_brochures = response.data.object.brochures;
        vm.s_events = response.data.object.events;

        vm.results = true;
        vm.loading = false;
      })
      .catch(function () {
        vm.results = false;
        vm.loading = false;
      })
    }
  },
  watch: {
    term: function () {
      this.debouncedSearch();
    },
    '$route.fullPath': function() {
      if(typeof this.$route.query.s != 'undefined') {
        this.term = this.$route.query.s;
      }
    }
  }
}
</script>
