<template>
  <div class="form-wrap">
    <div class="loading results" v-if="loading"></div>
    <div class="" v-if="!loading">
      <h2 v-if="title">Order inquiry</h2>
      <form ref="form" id="order-inquiry" @submit.prevent="sendform">

        <div class="form-group row">
          <label class="col-sm-3 col-lg-4 col-form-label">Product <span class="green">*</span></label>
          <div class="col-sm-5 col-lg-4">
            <select class="form-control" v-model="form.product" required>
              <option value="">Select product</option>
              <optgroup v-for="group in products" :label="group.group" :key="group.id">
                <option v-for="option in group.vals" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
              </optgroup>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-lg-4 col-form-label">Amount <span class="green">*</span></label>
          <div class="col-sm-5 col-lg-4">
            <select class="form-control" v-model="form.amount" required>
              <option v-for="option in amounts" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-lg-4 col-form-label">Have you operated with Bonalive&reg; products before? <span class="green">*</span></label>
          <div class="col-sm-9 col-lg-8 pt-4">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="used1" v-model="form.operatedbefore" name="usedprods" value="yes" class="custom-control-input" required>
              <label class="custom-control-label" for="used1">Yes</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="used2" v-model="form.operatedbefore" name="usedprods" value="no" class="custom-control-input">
              <label class="custom-control-label" for="used2">No</label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-lg-4 col-form-label">Other comments</label>
          <div class="col-sm-9 col-lg-6 ">
            <textarea class="form-control" v-model="form.message" placeholder="" ></textarea>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-lg-4 col-form-label">Your contact information</label>
          <div class="col-sm-9 col-lg-8">

            <p class="font-weight-normal" v-html="profile"></p>

            <p class="font-weight-normal mb-0">By submitting this inquiry form I agree and acknowledge that this is a non-binding product inquiry and that the inquiry will be processed within 1 working week. <br>I confirm that I am a medical professional. I agree to the <a href="https://www.bonalive.com/en/privacy-policy/" target="_blank">Bonalive® Privacy Policy</a>.</p>

            <div class="check-wrap pl-0">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="terms" v-model="form.terms" required>
                <label class="custom-control-label" for="terms">I agree. <span class="green">*</span></label>
              </div>
            </div>

          </div>
        </div>

        <div class="form-group">

          <div class="alert alert-info" v-if="message" v-html="message"></div>
          <ul>
            <li v-for="(val, key) in errors" :key="key">"{{ key }}": {{ val }}</li>
          </ul>

          <input class="btn btn-primary btn-full" type="submit" value="Send the inquiry" />
        </div>

      </form>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: Boolean,
    product: String
  },
  data: function () {
    return {
      loading: true,
      send: false,
      message: null,
      errors: [],
      profile: '',
      products: [ { id: 'granules-small', group: 'Bonalive® granules 0.5-0.8', vals: [ { value: 'Granules small 2.5 cc', text: '2.5 cc' }, { value: 'Granules small 5 cc', text: '5 cc' }, { value: 'Granules small no specified item', text: 'No specified item' } ] }, { id: 'granules-medium', group: 'Bonalive® granules 1.0-2.0', vals: [ { value: 'Granules medium 5 cc', text: '5 cc' }, { value: 'Granules medium 10 cc', text: '10 cc' }, { value: 'Granules medium no specified item', text: 'No specified item' } ] }, { id: 'putty', group: 'Bonalive® putty', vals: [ { value: 'Putty 2.5 cc', text: '2.5 cc' }, { value: 'Putty 5 cc', text: '5 cc' }, { value: 'Putty 10 cc', text: '10 cc' }, { value: 'Putty no specified item', text: 'No specified item' } ] }, { id: 'putty-mis', group: 'Bonalive® putty MIS', vals: [ { value: 'Putty MIS 1 x 5 cc cartridge with dispenser', text: '1 x 5 cc cartridge with dispenser' }, { value: 'Putty MIS 1 x 5 cc prefilled cartridge', text: '1 x 5 cc prefilled cartridge' }, { value: 'Putty MIS no specified item', text: 'No specified item' } ] } ],
      amounts: [ { value: '', text: 'Choose the amount (pcs)' }, { value: '1', text: '1' }, { value: '2', text: '2' }, { value: '3', text: '3' }, { value: '4', text: '4' }, { value: '5', text: '5' }, { value: 'No specified amount', text: 'No specified amount' } ],
      form: {
        product: '',
        amount: '',
        operatedbefore: false,
        message: '',
        terms: false
      }
    }
  },
  created: function () {
    var vm = this;

    if(vm.product.includes("granules (small")) {
      vm.products = [ { id: 'granules-small', group: 'Bonalive® granules 0.5-0.8', vals: [ { value: 'Granules small 2.5 cc', text: '2.5 cc' }, { value: 'Granules small 5 cc', text: '5 cc' }, { value: 'Granules small no specified item', text: 'No specified item' } ] } ];
    } else if(vm.product.includes("granules (medium")) {
      vm.products = [ { id: 'granules-medium', group: 'Bonalive® granules 1.0-2.0', vals: [ { value: 'Granules medium 5 cc', text: '5 cc' }, { value: 'Granules medium 10 cc', text: '10 cc' }, { value: 'Granules medium no specified item', text: 'No specified item' } ] } ];
    } else if(vm.product.includes("granules")) {
      vm.products = [ { id: 'granules-small', group: 'Bonalive® granules 0.5-0.8', vals: [ { value: 'Granules small 2.5 cc', text: '2.5 cc' }, { value: 'Granules small 5 cc', text: '5 cc' }, { value: 'Granules small no specified item', text: 'No specified item' } ] }, { id: 'granules-medium', group: 'Bonalive® granules 1.0-2.0', vals: [ { value: 'Granules medium 5 cc', text: '5 cc' }, { value: 'Granules medium 10 cc', text: '10 cc' }, { value: 'Granules medium no specified item', text: 'No specified item' } ] } ];
    } else if(vm.product.includes("putty")) {
      vm.products = [ { id: 'putty', group: 'Bonalive® putty', vals: [ { value: 'Putty 2.5 cc', text: '2.5 cc' }, { value: 'Putty 5 cc', text: '5 cc' }, { value: 'Putty 10 cc', text: '10 cc' }, { value: 'Putty no specified item', text: 'No specified item' } ] } ];
    } else if(vm.product.includes("MIS")) {
      vm.products = [ { id: 'putty-mis', group: 'Bonalive® putty MIS', vals: [ { value: 'Putty MIS 1 x 5 cc cartridge with dispenser', text: '1 x 5 cc cartridge with dispenser' }, { value: 'Putty MIS 1 x 5 cc prefilled cartridge', text: '1 x 5 cc prefilled cartridge' }, { value: 'Putty MIS no specified item', text: 'No specified item' } ] } ];
    }

    vm.loading = true;
    this.axios.get('/api/profile', { withCredentials: true })
    .then(function (response) {
      vm.loading = false;
      vm.profile = response.data.object.title + ' ' + response.data.object.firstname + ' ' + response.data.object.lastname + '<br>' + response.data.object.hospital + '<br>' + response.data.object.address + '<br>' + response.data.object.city + ' ' + response.data.object.zip + '<br>' + response.data.object.country_nice + '<br><br>' + response.data.object.email + '<br>Tel. ' + response.data.object.phone;
    })
    .catch(function () {
      this.$router.push('/support');
    })
  },
  methods: {
    sendform: function () {
      var vm = this;
      if(!vm.send) {
        vm.send = true;
        vm.message = null;
        vm.errors = [];

        this.axios.post('/api/forms/order-inquiry', this.qs.stringify(vm.form), { withCredentials: true })
        .then(function (response) {
          if(response.data.object.form.success) {
            vm.message = '<p><strong>Thank you for your inquiry!</strong></p><p>We will be in contact with you soon. Please note that in urgent situations (1-2 work days or less), we refer to our <a href="/support">support page</a>.</p>';
            vm.form.message = '';
            vm.form.product = '';
            vm.form.amount = '';
            vm.form.operatedbefore = false;
            vm.form.message = '';
            vm.form.terms = false;
          } else {
            vm.errors = response.data.object.form.errors;
          }
          vm.send = false;
        })
        .catch(function () {
          vm.send = false;
        })
      }
    }
  }
}
</script>
