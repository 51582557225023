<template>
  <div class="container-fluid login" :class="{ 'h-100' : !register }">
    <div class="row h-100" v-if="!register">
      <div class="col-12 col-lg-6 text-center login-box">
        <span class="logo">Smart Healing Center</span>
        <h1 v-if="!forgot && !register" class="w-info">Log in to your account</h1>
        <p v-if="!forgot && !register" class="login-info">Smart Healing Center is a complete platform, from surgeon to surgeon, facilitated by Bonalive Biomaterials. The platform provides you with patient cases, publications, and peer support in the use of Bonalive® bioactive glass, onsite and online.</p>
        <h1 v-if="forgot">Forgot your password?</h1>
        <div class="alert alert-primary" role="alert" v-if="error" v-html="error"></div>

        <form v-if="userid == 0 && !register">
          <input v-model="username" class="form-control loginUsername" placeholder="Username">
          <input v-if="!forgot" v-model="password" class="form-control" type="password" placeholder="Password" v-on:keyup.enter="login">
          <button v-if="!forgot" v-on:click="login" type="button" class="login-btn">Login</button>
          <button v-if="forgot" v-on:click="forgotpass" type="button" class="login-btn">Send me a new password</button>
          <p v-if="forgot"><a href="#" v-on:click.stop.prevent="forgot = false">Cancel</a></p>
        </form>
        <button v-if="userid > 0" v-on:click="logout" type="button" class="login-btn">Logout</button>

        <div class="row text-left mt-2">
          <div class="col-6">
            <p v-if="!forgot && !register && userid == 0"><strong>New user:</strong><br/><a href="#" v-on:click.stop.prevent="showRegister">Register to Smart Healing Center</a></p>
          </div>
          <div class="col-6">
            <p v-if="!forgot && !register && userid == 0"><br/><a href="#" v-on:click.stop.prevent="forgot = true">Forgot your password?</a></p>
          </div>
        </div>

        <div class="info text-left" v-if="0">
          <div v-if="info_open" class="info-header cursor-pointer" v-on:click="info_open = false">
            <p>Read more about the platform here <i class="far fa-chevron-up ml-2"></i></p>
          </div>
          <div v-if="!info_open" class="info-header cursor-pointer" v-on:click="info_open = true">
            <p>Read more about the platform here <i class="far fa-chevron-down ml-2"></i></p>
          </div>
          <div class="info-content" v-show="info_open" >
            <p><em>Smart Healing Center is a complete platform, for surgeons to surgeons, facilitated by Bonalive Biomaterials. The platform provides you with peer support in the use of Bonalive® products, onsite and online.</em></p>
          </div>
        </div>
        <span v-if="!info_open" class="bonalive-logo">Bonalive</span>

      </div>
      <div class="col-12 col-lg-6 py-5 image video-container">
        <video autoplay muted loop playsinline id="loginVideo">
          <source :src="[ '/assets/img/login_video_v2.mp4' ]" type="video/mp4">
          </video>
        </div>
      </div>
      <div class="row" v-if="register">
        <div class="col-12 py-5">
          <form class="profile register" @submit.prevent="registerSend">
            <span class="logo">Smart Healing Center</span>
            <h1>Register</h1>

            <div class="form-group row pt-5">
              <label class="col-sm-3 col-form-label">Title:</label>
              <div class="col-sm-9">
                <div class="icon icon-user"><select class="form-control" v-model="regform.title">
                  <option value="">Select title</option>
                  <option v-for="option in titles" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
                </select></div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Name: <span class="green">*</span></label>
              <div class="col-6 col-sm-4 pr-0">
                <div class="icon icon-user"><input type="text" class="form-control" v-model="regform.firstname" placeholder="First" required /></div>
              </div>
              <div class="col-6 col-sm-5">
                <input type="text" class="form-control" v-model="regform.lastname" placeholder="Last" required />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Profession: <span class="green">*</span></label>
              <div class="col-sm-9">
                <div class="icon icon-stethoscope"><select class="form-control" v-model="regform.profession" required>
                  <option value="">Select profession</option>
                  <option v-for="option in professions" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
                </select></div>
              </div>
            </div>

            <div class="form-group row" v-if="0">
              <label class="col-sm-3 col-form-label">Street address: <span class="green">*</span></label>
              <div class="col-sm-9">
                <div class="icon icon-mapmarker"><input type="text" class="form-control" v-model="regform.address"  /></div>
              </div>
            </div>

            <div class="form-group row" v-if="0">
              <label class="col-sm-3 col-form-label">City: <span class="green">*</span></label>
              <div class="col-sm-9">
                <div class="icon icon-mapmarker"><input type="text" class="form-control" v-model="regform.city"  /></div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Postal code: <span class="green">*</span></label>
              <div class="col-sm-9">
                <div class="icon icon-mapmarker"><input type="text" class="form-control" v-model="regform.zip" required /></div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Country: <span class="green">*</span></label>
              <div class="col-sm-9">
                <div class="icon icon-globe"><select class="form-control" v-model="regform.country" required>
                  <option v-for="option in countries" v-bind:value="option.value" :key="option.text">{{ option.text }}</option>
                </select></div>
                <div v-if="regform.country == 'US'" class="alert alert-info my-3">This site and its content is not intended for US customers.</div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label pr-0">Hospital / Organization: <span class="green">*</span></label>
              <div class="col-sm-9">
                <div class="icon icon-hospital"><input type="text" class="form-control" v-model="regform.hospital" required /></div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">E-mail: <span class="green">*</span></label>
              <div class="col-sm-9">
                <div class="icon icon-envelope"><input type="email" class="form-control icon icon-envelope" v-model="regform.email" required /></div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Phone: </label>
              <div class="col-sm-9">
                <div class="icon icon-phone"><input type="text" class="form-control" v-model="regform.phone" /></div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Area of interest: <span class="green">*</span></label>
              <div class="col-sm-9">
                <div class="check-wrap checkbox-small">
                  <div v-for="(option, index) in indications" class="custom-control custom-checkbox" :key="option.value">
                    <input type="checkbox" class="custom-control-input" :id="[ 'exp-' + index ]" v-model="regform.expertise" :value="option.value" >
                    <label class="custom-control-label" :for="[ 'exp-' + index ]">{{ option.text }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">ResearchGate: </label>
              <div class="col-sm-9">
                <div class="icon icon-rgate"><input type="text" class="form-control" v-model="regform.researchgate" /></div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">LinkedIn: </label>
              <div class="col-sm-9">
                <div class="icon icon-linkedin"><input type="text" class="form-control" v-model="regform.linkedin" /></div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Background: </label>
              <div class="col-sm-9">
                <textarea type="text" class="form-control" v-model="regform.background" placeholder="Here you can write about your educational and professional background."></textarea>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-form-label">Used Bonalive® bioactive glass before?</label>
              <div class="col-12">

                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="used1" v-model="regform.usedproducts" name="usedprods" value="yes" class="custom-control-input">
                  <label class="custom-control-label" for="used1">Yes</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="used2" v-model="regform.usedproducts" name="usedprods" value="no" class="custom-control-input">
                  <label class="custom-control-label" for="used2">No</label>
                </div>
              </div>
            </div>

            <div class="form-group row d-none">
              <label class="col-12 col-form-label">Choose your avatar <span class="green">*</span></label>
              <div class="col-12 avatar-select">

                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="avatar1" v-model="regform.avatar" value="1" class="custom-control-input">
                  <label class="custom-control-label avatar avatar-1" for="avatar1"></label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="avatar2" v-model="regform.avatar" value="2" class="custom-control-input">
                  <label class="custom-control-label avatar avatar-2" for="avatar2"></label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="avatar3" v-model="regform.avatar" value="3" class="custom-control-input">
                  <label class="custom-control-label avatar avatar-3" for="avatar3"></label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="avatar4" v-model="regform.avatar" value="4" class="custom-control-input">
                  <label class="custom-control-label avatar avatar-4" for="avatar4"></label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="avatar5" v-model="regform.avatar" value="5" class="custom-control-input">
                  <label class="custom-control-label avatar avatar-5" for="avatar5"></label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="avatar6" v-model="regform.avatar" value="6" class="custom-control-input">
                  <label class="custom-control-label avatar avatar-6" for="avatar6"></label>
                </div>

              </div>
            </div>

            <div class="check-wrap" v-if="0">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="terms" v-model="regform.terms" required>
                <label class="custom-control-label" for="terms">I accept the terms. <span class="green">*</span></label>
              </div>
            </div>

            <p class="mt-4">By registering you agree to the Bonalive Privacy Policy: <a href="https://www.bonalive.com/en/privacy-policy/" target="_blank">bonalive.com/en/privacy-policy</a></p>

            <p>By registering for this website, I assure that I am a healthcare professional, researcher or commercial representative of Bonalive products. Bonalive reserves the right to remove any users who do not match these roles.</p>

            <div class="alert alert-primary my-3" role="alert" v-if="error" v-html="error"></div>

            <button type="submit" class="btn btn-primary btn-full arrow-right">Register</button>
            <p class="small"><span class="green">*</span> Mandatory fields.</p>

            <p><a href="#" v-on:click.stop.prevent="register = false">Cancel</a></p>

          </form>
        </div>
      </div>
    </div>
  </template>

  <script>

  export default {
    data: function () {
      return {
        error: false,
        forgot: false,
        register: false,
        info_open: false,
        username: '',
        password: '',
        countries: this.shc_countries,
        indications: this.shc_indications,
        titles: this.shc_titles,
        professions: this.shc_professions,
        regform: {
          title: '',
          firstname: '',
          lastname: '',
          profession: '',
          address: '',
          city: '',
          zip: '',
          country: '',
          phone: '',
          hospital: '',
          email: '',
          expertise: [],
          researchgate: '',
          linkedin: '',
          background: '',
          usedproducts: '',
          avatar: 1,
          terms: true
        }
      }
    },
    computed: {
      userid: function() {
        return this.$parent.$parent.userid;
      }
    },
    created: function () {
      var vm = this;
      if(typeof vm.$route.query.logout != 'undefined') {
        vm.logout();
      }
    },
    methods: {
      login: function () {
        var vm = this;
        if(vm.password == '' || vm.username == '') {
          vm.error = 'Enter username and password!';
        } else {
          this.axios.post('/api/auth', 'username='+vm.username+'&password='+vm.password, { withCredentials: true })
          .then(function (response) {

            if(response.data.success) {
              vm.$parent.$parent.loggedin = true;
              vm.$parent.$parent.userid = response.data.object.id;
              vm.$parent.$parent.username = response.data.object.username;
              vm.$parent.$parent.fullname = response.data.object.fullname;
              vm.$parent.$parent.email = response.data.object.email;
              vm.username = 'anonymous';
              vm.password = '';
              vm.error = '';

              if(vm.$parent.$parent.start != '' && vm.$parent.$parent.start != '/') {
                vm.$router.push(vm.$parent.$parent.start);
              } else {
                vm.$router.push('/indication');
              }

            } else {
              vm.error = response.data.message;
            }

          })
          .catch(function (error) {
            vm.error = error;
          })
        }
      },
      logout: function () {
        var vm = this;
        this.axios.post('/api/auth', 'logout=true', { withCredentials: true })
        .then(function (response) {

          if(response.data.success) {
            vm.$parent.$parent.loggedin = false;
            vm.$parent.$parent.userid = 0;
            vm.$parent.$parent.username = 'anonymous';
            vm.$parent.$parent.start = '/';
          } else {
            vm.error = response.data.message;
          }

        })
        .catch(function (error) {
          vm.error = error;
        })
      },
      forgotpass: function () {
        var vm = this;
        if(vm.username == '') {
          vm.error = 'Enter username!';
        } else {
          this.axios.post('/api/auth/forgotpass', 'username='+vm.username, { withCredentials: true })
          .then(function (response) {

            if(response.data.success) {
              vm.error = response.data.message;
              vm.forgot = false;
            } else {
              vm.error = response.data.message;
            }

          })
          .catch(function (error) {
            vm.error = error;
          })
        }
      },
      showRegister: function() {
        this.error = false;
        this.register = true;
      },
      registerSend: function () {
        var vm = this;
        if(vm.regform.email == '' || vm.regform.firstname == '' || vm.regform.country == '' || vm.regform.expertise.length == 0) {
          vm.error = 'Fill all required fields!';
        } else {
          vm.error = '<i class="fa fa-spinner fa-spin"></i>';
          this.axios.post('/api/users', this.qs.stringify(vm.regform), { withCredentials: true })
          .then(function (response) {

            if(response.data.success) {
              vm.error = 'Thank you for registering! Please check your email for the username and password.';
              vm.register = false;
            } else {
              var tmp = []
              Object.keys(response.data.errors).forEach(function(key){ tmp.push(response.data.errors[key]); });
              vm.error = tmp.join("<br>", tmp);
            }

          })
          .catch(function (error) {
            vm.error = error;
          })
        }
      }
    }
  }
</script>
