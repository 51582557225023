<template>
  <div class="bg-wrap support">
    <div class="container">
      <div class="loading results" v-if="loading"></div>
      <div class="row" v-if="!loading">
        <div class="col-12">
          <div class="filter-items mb-4">
            <div class="form">
              <div><h4>Choose subject</h4></div>
              <div class="ml-2">
                <select class="custom-select" style="width: 220px;" v-model="form">
                  <option value="">Choose</option>
                  <option value="question">Ask us a question</option>
                  <option value="order-inquiry">Order inquiry</option>
                  <option value="feedback">Feedback</option>
                  <option value="medical-info-question">Medical info question</option>
                  <option value="product-complaint">Product complaint</option>
                  <option value="report-case">Share your patient case</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="user-content">
            <div class="row">
              <div class="col-12 col-lg-7 col-xl-8 mb-4 mb-lg-0">
                <div class="content-box contact-container form">
                  <div v-if="form == ''">
                    <h2>Support</h2>
                    <div class="landing-box p-0">
                      <div class="landing-wrap">
                        <div class="row link-boxes mobile-swipe">
                          <div class="col-6 col-md-4">
                            <a href="#" class="link-box green cursor-pointer" v-on:click.stop.prevent="form = 'question'">
                              <div class="icon"><img :src="[ '/assets/img/icon-askus.png' ]"></div>
                              <h5>Ask us a question</h5>
                              <p>Anything, any time.</p>
                            </a>
                          </div>
                          <div class="col-6 col-md-4">
                            <a href="#" class="link-box green" v-on:click.stop.prevent="form = 'order-inquiry'">
                              <div class="icon"><img :src="[ '/assets/img/Products_Pictogram.png' ]"></div>
                              <h5>Order inquiry</h5>
                              <p>Send us an inquiry and we will connect you with the local representative.</p>
                            </a>
                          </div>
                          <div class="col-6 col-md-4">
                            <a href="#" class="link-box green" v-on:click.stop.prevent="form = 'feedback'">
                              <div class="icon"><img :src="[ '/assets/img/Feedback_Pictogram.png' ]"></div>
                              <h5>Feedback</h5>
                              <p>All feedback is of great value to us.</p>
                            </a>
                          </div>
                          <div class="col-6 col-md-4">
                            <a href="#" class="link-box green" v-on:click.stop.prevent="form = 'medical-info-question'">
                              <div class="icon"><img :src="[ '/assets/img/Medical_info_question_Pictogram.png' ]"></div>
                              <h5>Medical info question</h5>
                              <p>Ask questions related to our products and their use.</p>
                            </a>
                          </div>
                          <div class="col-6 col-md-4">
                            <a href="#" class="link-box green" v-on:click.stop.prevent="form = 'product-complaint'">
                              <div class="icon"><img :src="[ '/assets/img/Complaint_Pictogram.png' ]"></div>
                              <h5>Product complaint</h5>
                              <p>Complete the form to let us know of any adverse event.</p>
                            </a>
                          </div>
                          <div class="col-6 col-md-4">
                            <a href="#" class="link-box green" v-on:click.stop.prevent="form = 'report-case'">
                              <div class="icon"><img :src="[ '/assets/img/Bonalive_Pictogram_RGB-25.png' ]"></div>
                              <h5>Share your patient case</h5>
                              <p>Fill the electronic patient case form.</p>
                            </a>
                          </div>
                        </div>
                        <div class="mobile-swipe-nav"><span class="left hidden"></span><span class="right"></span></div>
                      </div>
                    </div>
                  </div>
                  <FormQuestion v-if="form == 'question'" />
                  <FormMedicalQuestion v-if="form == 'medical-info-question'" />
                  <FormFeedback v-if="form == 'feedback'" />
                  <FormComplaint v-if="form == 'product-complaint'" />
                  <FormOrderInquiry v-if="form == 'order-inquiry'" :title="true" :product="'all'" />
                  <FormCaseReport v-if="form == 'report-case'" />
                </div>
              </div>
              <div class="col-12 col-lg-5 col-xl-4">
                <div class="content-box contact-container info">
                  <h2>Contact information</h2>
                  <h3>Local representative</h3>
                  <div class="contact" v-if="typeof contacts.local == 'object' && contacts.local.name != '-'">
                    <div class="image"><img :src="contacts.local.thumbnail"></div>
                    <p><strong>{{ contacts.local.name }}</strong><br>{{ contacts.local.title }}<br>{{ contacts.local.phone }}<br>{{ contacts.local.email }}</p>
                  </div>
                  <p v-if="typeof contacts.local != 'object'">In case we have distribution in your area, we will assign a local distributor for you shortly.</p>
                  <p v-if="typeof contacts.local == 'object' && contacts.local.name == '-'">Bonalive is not distributed in your area.</p>
                  <h3>Medical information</h3>
                  <div class="contact">
                    <div class="image"><img :src="contacts.medical.thumbnail"></div>
                    <p><strong>{{ contacts.medical.name }}</strong><br>{{ contacts.medical.title }}<br>{{ contacts.medical.phone }}<br v-if="contacts.medical.phone != ''">{{ contacts.medical.email }}</p>
                  </div>
                  <h3>Headquarters</h3>
                  <div class="contact">
                    <div class="image"><img :src="[ '/assets/img/Headquarters_Pictogram.png' ]"></div>
                    <p><strong>{{ contacts.hq.name }}</strong><br>{{ contacts.hq.title }}<br>{{ contacts.hq.phone }}<br>{{ contacts.hq.email }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import FormFeedback from '../components/FormFeedback.vue'
import FormQuestion from '../components/FormQuestion.vue'
import FormMedicalQuestion from '../components/FormMedicalQuestion.vue'
import FormComplaint from '../components/FormComplaint.vue'
import FormOrderInquiry from '../components/FormOrderInquiry.vue'
import FormCaseReport from '../components/FormCaseReport.vue'

export default {
  components: {
    FormFeedback,
    FormComplaint,
    FormQuestion,
    FormMedicalQuestion,
    FormOrderInquiry,
    FormCaseReport
  },
  data: function () {
    return {
      loading: true,
      form: '',
      contacts: {
        local: false,
        medical: false,
        hq: false
      },
      items: []
    }
  },
  created: function () {
    var vm = this;
    vm.load();
    if(typeof vm.$route.query.form != 'undefined') {
      vm.form = vm.$route.query.form;
    }
  },
  methods: {
    load: function() {
      var vm = this;
      vm.loading = true;
      this.axios.get('/api/contacts', { withCredentials: true })
      .then(function (response) {
        vm.contacts = response.data.object;
        vm.loading = false;
      })
      .catch(function () {
        this.$router.push('/');
      })
    }
  }
}
</script>
