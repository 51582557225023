<template>
  <div class="indication">
    <div class="indication-wrap">
      <div class="loading results" v-if="loading"></div>
      <div class="container" v-if="!loading">
        <div class="row">
          <div class="col-12 col-lg-6">
            <img :src="[ '/assets/img/landing_' + image + '.png' ]" />
          </div>
          <div class="col-12 col-lg-6">
            <div class="indication-info">
              <h1>{{ title }}</h1>
              <div v-html="content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="!loading">

      <div class="row">
        <div class="col-12 px-0">
          <h1 class="text-center my-5">Overview</h1>
        </div>
      </div>

      <div class="row result-related" v-if="videos.length > 0">
        <div class="col-12 col-lg-4 pl-md-0">
          <h3>Video</h3>
          <p class="mt-3"><router-link to="/login" class="more-link">Login to see all »</router-link></p>
        </div>
        <div class="col-12 col-lg-8">
          <div class="related">
            <div class="row" v-for="(item) in videos" :key="item.id">
              <div class="col-12 col-md-5 mb-3 mb-md-0">
                <a class="cursor-pointer" v-on:click.prevent="showModal = true"><div class="thumb-crop" :style="{ backgroundImage: 'url(' + item.thumb + ')' }"><img :src="item.thumb" /></div></a>
              </div>
              <div class="col-12 col-md-7 my-auto pl-lg-45">
                <a class="cursor-pointer" v-on:click.prevent="showModal = true">{{ item.title }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row result-related" v-if="cases.length > 0">
        <div class="col-12 col-lg-4 pl-md-0">
          <h3>Cases</h3>
          <p class="mt-3"><router-link :to="{ path: '/login' }" class="more-link" >Login to see all »</router-link></p>
        </div>
        <div class="col-12 col-lg-8">
          <div class="related">
            <div class="row" v-for="(item) in cases" :key="item.id">
              <div class="col-12 col-md-5 mb-3 mb-md-0">
                <a class="cursor-pointer" v-on:click.prevent="showModal = true"><img :src="item.thumb" /></a>
              </div>
              <div class="col-12 col-md-7 my-auto pl-lg-45">
                <a class="cursor-pointer" v-on:click.prevent="showModal = true">{{ item.title }} <em>{{ item.hospital }}, {{ item.country }}</em></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row result-related" v-if="articles.length > 0">
        <div class="col-12 col-lg-4 pl-md-0">
          <h3>Publications</h3>
          <p class="mt-3"><router-link :to="{ path: '/login' }" class="more-link">Login to see all »</router-link></p>
        </div>
        <div class="col-12 col-lg-8">
          <div class="related">
            <div class="row" v-for="(item) in articles" :key="item.id">
              <div class="col-12 col-md-5 mb-3 mb-md-0">
                <a class="cursor-pointer" v-on:click.prevent="showModal = true"><img :src="[ '/assets/img/pl-article.png' ]" /></a>
              </div>
              <div class="col-12 col-md-7 my-auto pl-lg-45">
                <a class="cursor-pointer" v-on:click.prevent="showModal = true">{{ item.title }} <em>{{ item.author }} {{ item.pub_year }}</em></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row result-related" v-if="brochures.length > 0">
        <div class="col-12 col-lg-4 pl-md-0">
          <h3>Brochures</h3>
          <p class="mt-3"><router-link :to="{ path: '/login' }" class="more-link">Login to see all »</router-link></p>
        </div>
        <div class="col-12 col-lg-8">
          <div class="related">
            <div class="row" v-for="(item) in brochures" :key="item.id">
              <div class="col-12 col-md-5 mb-3 mb-md-0">
                <a class="cursor-pointer" v-on:click.prevent="showModal = true"><div class="thumb-crop full-height" :style="{ backgroundImage: 'url(' + item.thumb + ')' }"><img :src="item.thumb" /></div></a>
              </div>
              <div class="col-12 col-md-7 my-auto pl-lg-45">
                <a class="cursor-pointer" v-on:click.prevent="showModal = true">{{ item.title }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Login to view content</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModal = false">
                    <span aria-hidden="true" >&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12">
                      <p>To view the content, please <router-link :to="{ path: '/login' }">log in or register</router-link> to Smart Healing Center.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
export default {
  data: function () {
    return {
      loading: true,
      visible: false,
      title: '',
      content: '',
      image: '',
      ind_id: '',
      cases: [],
      articles: [],
      videos: [],
      brochures: [],
      showModal: false
    }
  },
  created: function () {
    var vm = this;
    if(typeof vm.$route.params.id != 'undefined') {
      vm.visible = vm.$route.params.id;
      vm.checkimage();
    }

    vm.$parent.$parent.start = '/indication'+vm.$route.fullPath.substr(6)

  },
  methods: {
    load: function() {
      var vm = this;
      vm.loading = true;

      var area = '';
      if(typeof vm.$route.query.area != 'undefined') area = vm.$route.query.area;

      this.axios.get('/api/indicationtrial/' + vm.visible + '?area=' + area, { withCredentials: true })
      .then(function (response) {
        if(response.data.success) {

          vm.loading = false;

          vm.title = response.data.object.title;
          vm.content = response.data.object.content;
          vm.cases = response.data.object.cases;
          vm.articles = response.data.object.articles;
          vm.videos = response.data.object.videos;
          vm.brochures = response.data.object.brochures;

          vm.ind_id = response.data.object.indication_id;

        } else {
          vm.$router.push('/');
        }
      }).catch(function () {
        vm.$router.push('/');
      })

    },
    checkimage: function() {
      var vm = this;
      if(vm.$route.params.id == 'mastoid' || vm.$route.params.id == 'spine') {
        vm.image = vm.$route.params.id;
      } else {
        if(typeof vm.$route.query.area != 'undefined') {
          vm.image = vm.$route.query.area;
        } else {
          vm.image = 'arms';
        }
      }
    }
  },
  watch: {
    visible: function () {
      this.load();
    },
    $route(to) {
      var vm = this;
      if(typeof to.params.id != 'undefined') {
        vm.visible = to.params.id;
        vm.checkimage();
      }
    }
  }
}
</script>
