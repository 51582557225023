<template>
  <div class="form-wrap">
    <div class="container">
      <h2>Product complaint</h2>
      <p>At Bonalive we take all product complaints very seriously. Our ultimate goal is to identify the root cause of all product failures, implement counter-measures and constantly develop based on new insights. In order to do this and to comply with regulatory reporting requirements, we ask you to complete our product complaint form in any case of adverse performance of our products.</p>
      <form ref="form" id="contact" @submit.prevent="sendform">
        <div class="form-group">
          <input class="form-control" v-model="form.event_descr" placeholder="Event description" required />
        </div>
        <div class="form-group">
          <v-date-picker v-model="form.date">
            <template v-slot="{ inputValue, inputEvents }">
              <input class="form-control" :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </div>

        <div class="form-group">
          <h3>Contact</h3>
          <input class="form-control" v-model="form.surgeon" placeholder="Surgeon" />
        </div>
        <div class="form-group">
          <input class="form-control" v-model="form.hospital" placeholder="Hospital" />
        </div>
        <div class="form-group">
          <input class="form-control" v-model="form.city" placeholder="City" />
        </div>
        <div class="form-group">
          <select class="form-control" v-model="form.country">
            <option v-for="option in countries" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>

        <div class="form-group">
          <h3>Patient demographics and history</h3>
          <input class="form-control" v-model="form.patient_age" placeholder="Age (years)" />
        </div>
        <div class="form-group">
          <select v-model="form.patient_gender" class="form-control">
            <option value="">Gender</option>
            <option>male</option>
            <option>female</option>
            <option>other</option>
          </select>
        </div>
        <div class="form-group">
          <textarea v-model="form.patient_history" class="form-control" placeholder="History of the patient that might have an impact on the event (e.g. chronic systemic diseases, medications and other additional treatments)" ></textarea>
        </div>

        <div class="form-group">
          <h3>Operation related information</h3>
          <textarea v-model="form.operation_descr" class="form-control" placeholder="Short description of the surgical procedure and implantation of Bonalive" ></textarea>
        </div>
        <div class="form-group">
          <input v-model="form.operation_other" class="form-control" placeholder="Other treatments (e.g. antibiotics, drainage)" />
        </div>
        <div class="form-group">
          <select v-model="form.operation_mastoid" class="form-control">
            <option value="">In mastoid surgery</option>
            <option>CWD</option>
            <option>CWU</option>
            <option>-</option>
          </select>
        </div>
        <div class="form-group">
          <select v-model="form.post_infection" class="form-control">
            <option value="">In case of post op infections</option>
            <option>Recurrent</option>
            <option>Acute</option>
            <option>-</option>
          </select>
        </div>
        <div class="form-group">
          <input v-model="form.infection_time" class="form-control" placeholder="Time between implantation and post-op infection" />
        </div>
        <div class="form-group">
          <input v-model="form.infection_result" class="form-control" placeholder="Bacterial culture results (Taken from wound or deeper from the operated defect?)" />
        </div>
        <div class="form-group">
          <textarea v-model="form.operation_surgeon_opinion" class="form-control" placeholder="In the opinion of the surgeon, what could be the reason for the event? Is the event product-related (Bonalive)?"></textarea>
        </div>

        <div class="form-group">
          <h3>Bonalive product used</h3>
          <div class="prod-wrap">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="prod-8" v-model="form.product" value="Bonalive® granules small" >
              <label class="custom-control-label" for="prod-8">Bonalive® granules small</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="prod-9" v-model="form.product" value="Bonalive® granules medium" >
              <label class="custom-control-label" for="prod-9">Bonalive® granules medium</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="prod-10" v-model="form.product" value="Bonalive® granules large" >
              <label class="custom-control-label" for="prod-10">Bonalive® granules large</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="prod-11" v-model="form.product" value="Bonalive® putty" >
              <label class="custom-control-label" for="prod-11">Bonalive® putty</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="prod-12" v-model="form.product" value="Bonalive® putty MIS" >
              <label class="custom-control-label" for="prod-12">Bonalive® putty MIS</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <input v-model="form.product_cat" class="form-control" placeholder="REF" />
        </div>
        <div class="form-group">
          <input v-model="form.product_batch" class="form-control" placeholder="LOT" />
        </div>
        <div class="form-group">
          <input v-model="form.product_amount" class="form-control" placeholder="Size of the defect / amount of Bonalive used" />
        </div>
        <div class="form-group">
          <input v-model="form.product_mix" class="form-control" placeholder="Mixed with auto/allogenous bone? (Mixing ratio?)" />
        </div>

        <div class="form-group">
          <h3>Current status</h3>
          <textarea v-model="form.current_status" class="form-control" placeholder="Current status of the patient"></textarea>
        </div>
        <div class="form-group">
          <input v-model="form.possible_followup" class="form-control" placeholder="Possible follow up actions (e.g. medication, re-operation)" />
        </div>
        <div class="form-group">
          <input v-model="form.health_risk" class="form-control" placeholder="For reporting needs; did the event cause serious health risk for the patient?" />
        </div>

        <div class="form-group">
          <div class="alert alert-info" v-if="message"><p>{{ message }}</p></div>
          <ul>
            <li v-for="(val, key) in errors" :key="key">"{{ key }}": {{ val }}</li>
          </ul>
          <input class="btn btn-primary" type="submit" value="Send" />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      send: false,
      countries: this.shc_countries,
      message: null,
      errors: [],
      form: {
        event_descr: '',
        date: new Date(),
        surgeon: '',
        hospital: '',
        country: '',
        patient_age: '',
        patient_gender: '',
        patient_history: '',
        operation_descr: '',
        operation_other: '',
        operation_mastoid: '',
        post_infection: '',
        infection_time: '',
        infection_result: '',
        operation_surgeon_opinion: '',
        product: [],
        product_cat: '',
        product_batch: '',
        product_amount: '',
        product_mix: '',
        current_status: '',
        possible_followup: '',
        health_risk: ''
      }
    }
  },
  methods: {
    sendform: function () {
      var vm = this;
      if(!vm.send) {
        vm.send = true;
        vm.message = null;
        vm.errors = [];

        this.axios.post('/api/forms/complaint', this.qs.stringify(vm.form), { withCredentials: true })
        .then(function (response) {
          if(response.data.object.form.success) {
            vm.message = 'Message sent successfully!';
            vm.form.event_descr = '';
            vm.form.date = new Date();
            vm.form.surgeon = '';
            vm.form.hospital = '';
            vm.form.country = '';
            vm.form.patient_age = '';
            vm.form.patient_gender = '';
            vm.form.patient_history = '';
            vm.form.operation_descr = '';
            vm.form.operation_other = '';
            vm.form.operation_mastoid = '';
            vm.form.post_infection = '';
            vm.form.infection_time = '';
            vm.form.infection_result = '';
            vm.form.operation_surgeon_opinion = '';
            vm.form.product = [];
            vm.form.product_cat = '';
            vm.form.product_batch = '';
            vm.form.product_amount = '';
            vm.form.product_mix = '';
            vm.form.current_status = '';
            vm.form.possible_followup = '';
            vm.form.health_risk = '';
          } else {
            vm.errors = response.data.object.form.errors;
          }
          vm.send = false;
          window.scrollTo(0, 0);
        })
        .catch(function () {
          vm.send = false;
        })
      }
    }
  }
}
</script>
