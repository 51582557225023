<template>
  <div class="form-wrap">
    <div class="">
      <h2>Medical info question</h2>
      <p>Do you have a question related to our products and their use? Ask our Medical Info service and you will have an answer within 2 working days.</p>
      <form ref="form" id="contact" @submit.prevent="sendform">
        <div class="form-group">
          <textarea class="form-control" v-model="form.message" placeholder="" required></textarea>
        </div>
        <div class="form-group">
          <div class="alert alert-info" v-if="message"><p>{{ message }}</p></div>
          <ul>
            <li v-for="(val, key) in errors" :key="key">"{{ key }}": {{ val }}</li>
          </ul>
          <input class="btn btn-primary" type="submit" value="Send" />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  template: `
  `,
  data: function () {
    return {
      send: false,
      message: null,
      errors: [],
      form: {
        message: ''
      }
    }
  },
  methods: {
    sendform: function () {
      var vm = this;
      if(!vm.send) {
        vm.send = true;
        vm.message = null;
        vm.errors = [];

        this.axios.post('/api/forms/medical-question', this.qs.stringify(vm.form), { withCredentials: true })
        .then(function (response) {
          if(response.data.object.form.success) {
            vm.message = 'Message sent successfully!';
            vm.form.message = '';
          } else {
            vm.errors = response.data.object.form.errors;
          }
          vm.send = false;
        })
        .catch(function () {
          vm.send = false;
        })
      }
    }
  }
}
</script>
