<template>
  <div class="form-wrap">
    <div class="">
      <h2>Feedback</h2>
      <p>All feedback is of great value to us: positive product outcome, your experiences using Bonalive Pro, daily success stories – all feedback is welcome.</p>

      <form ref="form" id="contact" @submit.prevent="sendform">
        <div class="form-group">
          <h3>Bonalive product</h3>
          <div class="prod-wrap">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="prod-8" v-model="form.product" value="Bonalive® granules small" >
              <label class="custom-control-label" for="prod-8">Bonalive® granules small</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="prod-9" v-model="form.product" value="Bonalive® granules medium" >
              <label class="custom-control-label" for="prod-9">Bonalive® granules medium</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="prod-10" v-model="form.product" value="Bonalive® granules large" >
              <label class="custom-control-label" for="prod-10">Bonalive® granules large</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="prod-11" v-model="form.product" value="Bonalive® putty" >
              <label class="custom-control-label" for="prod-11">Bonalive® putty</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="prod-12" v-model="form.product" value="Bonalive® putty MIS" >
              <label class="custom-control-label" for="prod-12">Bonalive® putty MIS</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <h3>Contact</h3>
          <input class="form-control" placeholder="Surgeon" v-model="form.surgeon" required />
        </div>
        <div class="form-group">
          <input class="form-control" placeholder="Hospital" v-model="form.hospital" required />
        </div>
        <div class="form-group">
          <input class="form-control" placeholder="City" v-model="form.city" required />
        </div>
        <div class="form-group">
          <select class="form-control" v-model="form.country">
            <option v-for="option in countries" v-bind:value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
        <div class="form-group">
          <h3>Feedback</h3>
          <textarea class="form-control" v-model="form.feedback" placeholder="" required></textarea>
        </div>
        <div class="form-group">
          <div class="alert alert-info" v-if="message"><p>{{ message }}</p></div>
          <ul>
            <li v-for="(val, key) in errors" :key="key">"{{ key }}": {{ val }}</li>
          </ul>

          <input class="btn btn-primary" type="submit" value="Send" />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      send: false,
      message: null,
      countries: this.shc_countries,
      errors: [],
      form: {
        product: [],
        surgeon: '',
        hospital: '',
        city: '',
        country: '',
        feedback: ''
      }
    }
  },
  methods: {
    sendform: function () {
      var vm = this;
      if(!vm.send) {
        vm.send = true;
        vm.message = null;
        vm.errors = [];

        this.axios.post('/api/forms/feedback', this.qs.stringify(vm.form), { withCredentials: true })
        .then(function (response) {
          if(response.data.object.form.success) {
            vm.message = 'Message sent successfully!';
            vm.form.product = [];
            vm.form.surgeon = '';
            vm.form.hospital = '';
            vm.form.city = '';
            vm.form.country = '';
            vm.form.feedback = '';
          } else {
            vm.errors = response.data.object.form.errors;
          }
          vm.send = false;
        })
        .catch(function () {
          vm.send = false;
        })
      }
    }
  }
}
</script>
